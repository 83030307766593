<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
            <!--     文字-->
            <div class="layer2" style="height: 100%">
            <div class="title1" v-sliden-instep="{duration:800}" style="font-weight: bolder;color: #282d7d">Fiber Optic Wellbore Leakage<br>Point Real-time Prediction Platform</div>

            <div  v-sliden-instep="{duration:1200}"  class="title2" style="font-weight: bolder;margin-left: 0px;color: #282d7d">
              <li>Process-related services are provided by Antonoil's offline technical personnel</li>
              <li>GPT development services are provided by Antonoil's AI professionals </li>
            </div>
              <div style="display: flex;width: 30vw;margin: auto;margin-left: 0px;position: absolute;bottom: 0vh">
                <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto"  @click="clickConsult('Oil & Gas Generic AI')">
                  Product Inquiry
                </div>
                <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto" @click="freeExperience">
                  Free Trial
                </div>
              </div>
            </div>
          </div>
          <div>
<!--            <div class="screenmake1">-->
<!--              <div class="heighLight"> Solving user pain points</div>-->
<!--              <div class="grid">-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="imgDesign">-->
<!--                      <img src="@/assets/frackIcon1.png">-->
<!--                    </div>-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="Title1">High Difficulty In Managing And Applying Geological Data And Project Engineering Data</div>-->
<!--                      <div class="Title2">-->
<!--                        Oil & Gas wellbore monitoring involves multiple data sources such as geological data, engineering data, and environmental data. These data come from different sources and have different formats, making integration difficult. There is a lack of unified data standards and interfaces, resulting in ineffective data fusion and utilization.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="imgDesign">-->
<!--                      <img src="@/assets/frackIcon2.png">-->
<!--                    </div>-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="Title1">Limited Expert Engineering Experience Is Not Replicable</div>-->
<!--                      <div class="Title2">-->
<!--                        Oil & Gas development projects rely relatively heavily on experts/engineers with rich experience in historical engineering projects. This valuable engineering experience is not preserved in the form of knowledge, making it not replicable and unable to cover a large number of projects in a short time.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="imgDesign">-->
<!--                      <img src="@/assets/frackIcon3.png">-->
<!--                    </div>-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="Title1">The Real-time Performance And Accuracy of Detection Technology Are Insufficient</div>-->
<!--                      <div class="Title2">-->
<!--                        Optical fiber leak detection, while monitoring wellbore data, suffers from low positioning accuracy and inefficient real-time processing. In complex wells, it lacks timeliness and accuracy, hindering timely risk warnings, compromising safety and efficiency, and causing economic losses and potential hazards.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>

<!--          <div class="screenmake2"  style="background-color: transparent">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">-->
<!--              Function introduction</div>-->
<!--            <div v-sliden-in="{duration:800}" class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back">-->

<!--                  <div class="titleDesign">-->
<!--                    <div class="title1">DAS Heat Map-->
<!--                    </div>-->
<!--                    <div class="title2">-->
<!--                      After cleaning and organizing the fiber optic detection data, these data are presented in the form of a heat map on the front-end interface, displaying the changes of various parameters within the wellbore in real-time. This heat map not only reflects the operational status of the wellbore in real-time but also supports zooming, dragging, and viewing detailed data for specific depths and time intervals.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back1" >-->
<!--                  <div class="titleDesign">-->
<!--                    <div class="title1">DTS Heat Map-->
<!--                    </div>-->
<!--                    <div class="title2">-->
<!--                      After cleaning and organizing the user's data, it is rendered into a heat map on the front-end page, showing real-time data changes. Real-time monitoring of the wellbore status is enabled, with operations such as zooming, dragging, and viewing specific depths and times available.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back2">-->

<!--                  <div class="titleDesign">-->
<!--                    <div class="title1">DAS Leak Detection-->
<!--                    </div>-->
<!--                    <div class="title2">-->
<!--                      Real-time data collected by fiber optic sensors are input into a pre-trained linear regression model to achieve real-time prediction of the wellbore status. Through this prediction, changes in various parameters within the wellbore can be effectively monitored, identifying potential leak points or other anomalies in a timely manner.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          <div class="screenmake3" style="padding-top: 6vh">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.1vw"> Business Pain Points and Challenges</div>
<!--            <div v-sliden-in="{duration:800}" class="line"></div>-->
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Delay in Real-time Monitoring
                    </div>
                    <div class="title2">
                      Delays in the transmission and processing of fiber-optic monitoring data can lead to untimely leakage point predictions, thereby affecting the company's decision-making and response speed in addressing leakage points.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/late.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Data Inaccuracy
                    </div>
                    <div class="title2">
                      Fiber-optic sensors are susceptible to external interference, which may result in inaccurate data acquisition, further impacting the reliability of leakage point predictions and increasing potential risks.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/efficiency.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Inefficiency
                    </div>
                    <div class="title2">
                      Due to the high demands for maintenance and updates of the fiber-optic monitoring system, companies face challenges in resource allocation, which can hinder overall operational efficiency and cause delays in the progress of other projects.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div ref="freeplay" class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Technical Selection</div>
            <div v-sliden-in="{duration:800}" class="heighLight1">Utilizing the linear regression algorithm to swiftly reveal the impact of various parameters on the results, providing a foundation for further analysis and decision-making. It boasts high computational efficiency, making it suitable for real-time or near-real-time analysis of large volumes of data. The regression coefficients directly reflect the marginal contribution of independent variables to the dependent variable, making the model transparent and easily interpretable, facilitating intuitive understanding and optimization adjustments by business personnel and decision-makers.</div>
          </div>
          <div class="screenmake0" style="padding-top: 6vh">
            <div v-sliden-in="{duration:800}" class="heighLight">Functional Experience</div>
            <div v-sliden-in="{duration:800}" class="heighLight1">Based on data collected by underground fiber optic sensors, the data is analyzed and presented with features such as
              <span style="font-weight: bold">heat map display </span>、<span style="font-weight: bold">leak point prediction</span>.You can <span style="font-weight: bold;color: red;">use function below, free experience</span>!  <br>If you have cooperation intention and product consultation, please <span style="color: #FF6400;cursor: pointer" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>. </div>
            <div class="grid">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="DAS" name="first">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. Data Source:
                          </div>
                          <div class="Title2">
                            Data collected by the fiber optic acquisition box and processed.
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2.  Operation Process：<span class="Title2" > ①Click 【Download DAS sample file】 to download DAS sample data; click 【Download DAS sample file】 to upload DAS document data.
②Click 【Generate heat map】、【Predict】, and after a while, processing the data can generate a heat map and prediction results.

                 </span>
                          </div>
                          <div class="Title2" style="display: flex">
                          </div>
                        </div>
                        <div style="display: flex">
                          <!-- <div class="Title1">
                            *&nbsp;  注意事项：
                          </div>
                          <div class="Title2">
                            模型选择对数据分析的准确度至关重要，选择与您上传数据所在区域相关的模型可提高数据分析准确率。
                          </div> -->
                        </div>



                      </div>
                      <div ref="iframeContainer" class="iframeStyle">
                        <iframe :src="iframeUrl1" frameborder="0" allowfullscreen></iframe> </div>



                    </div>


                  </div>
                </el-tab-pane>
                <el-tab-pane label="DTS" name="second">
                  <div class="card">
                    <div class="back">


                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. Data Source:
                          </div>
                          <div class="Title2">
                            Data collected by the fiber optic acquisition box and processed.
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2. Operation Process：<span class="Title2" >     ①Click 【Download DTS sample file】 to download DTS sample data; click 【Upload DTS folder】 to upload DTS document data.
②Click 【Generate heat map】, and after a while, processing the data can generate a heat map.

                  </span>
                          </div>

                        </div>




                      </div>
                      <div ref="iframeContainer" class="iframeStyle">
                        <iframe :src="iframeUrl2" frameborder="0" allowfullscreen></iframe>
                      </div>




                    </div>


                  </div>
                </el-tab-pane>
              </el-tabs>


            </div>

          </div>
          <bottomTip></bottomTip>
        </div>

      <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div class="screen1">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->


          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Fiber Optic Wellbore Leak Real-time Prediction</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 23px;color:#2c3e50;text-align: left">
              <li>Real-time analysis of geological stratification using models</li>
              <li>Automatic generation of early warning messages to assist engineers in adjusting drilling parameters promptly</li>
            </div>
          </div>
          <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
            Product Inquiry
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>


          <div class="screenmake21" style="padding-top: 0;background-color: transparent">
            <div class="heighLight" style="height: fit-content"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Delay in Real-time Monitoring
                    </div>
                    <div class="title2">
                      Delays in the transmission and processing of fiber-optic monitoring data can lead to untimely leakage point predictions, thereby affecting the company's decision-making and response speed in addressing leakage points.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/late.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Data Inaccuracy
                    </div>
                    <div class="title2">
                      Fiber-optic sensors are susceptible to external interference, which may result in inaccurate data acquisition, further impacting the reliability of leakage point predictions and increasing potential risks.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/efficiency.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Inefficiency
                    </div>
                    <div class="title2">
                      Due to the high demands for maintenance and updates of the fiber-optic monitoring system, companies face challenges in resource allocation, which can hinder overall operational efficiency and cause delays in the progress of other projects.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="screenmake2" style="background-color: transparent">
          <div class="heighLight">
            Function introduction</div>
          <div class="grid" style="margin-top: 10px">
            <div class="card">
              <div class="back">

                <div class="titleDesign">
                  <div class="title1">DAS Heat Map
                  </div>
                  <div class="title2">
                    After cleaning and organizing the fiber optic detection data, these data are presented in the form of a heat map on the front-end interface, displaying the changes of various parameters within the wellbore in real-time. This heat map not only reflects the operational status of the wellbore in real-time but also supports zooming, dragging, and viewing detailed data for specific depths and time intervals.  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back" >
                <div class="titleDesign">
                  <div class="title1">DTS Heat Map
                  </div>
                  <div class="title2">
                    After cleaning and organizing the user's data, it is rendered into a heat map on the front-end page, showing real-time data changes. Real-time monitoring of the wellbore status is enabled, with operations such as zooming, dragging, and viewing specific depths and times available.  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back">

                <div class="titleDesign">
                  <div class="title1">DAS Leak Detection
                  </div>
                  <div class="title2">
                    Real-time data collected by fiber optic sensors are input into a pre-trained linear regression model to achieve real-time prediction of the wellbore status. Through this prediction, changes in various parameters within the wellbore can be effectively monitored, identifying potential leak points or other anomalies in a timely manner.</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake6">

        <div class="screenmake2" style="background: white;">
          <div class="heighLight">   <span>Technical Selection</span></div>
          <div class="grid">
            <div class="card">
              <div class="back" style="background-color: transparent;margin-top: 0;">
                <div class="titleDesign" style="margin-top: 0;">
                  <div class="title2" style="margin-left: 0">
                    Utilizing the linear regression algorithm to swiftly reveal the impact of various parameters on the results, providing a foundation for further analysis and decision-making. It boasts high computational efficiency, making it suitable for real-time or near-real-time analysis of large volumes of data. The regression coefficients directly reflect the marginal contribution of independent variables to the dependent variable, making the model transparent and easily interpretable, facilitating intuitive understanding and optimization adjustments by business personnel and decision-makers.  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="screenmake2" style="background: #D2E3FF;">
          <div class="heighLight">   <span>Functional Experience</span></div>
          <div class="grid">
            <div class="card">
              <div class="back" style="background-color: transparent;margin-top: 0;">
                <div class="titleDesign" style="margin-top: 0;">
                  <div class="title2" style="margin-left: 0">
                    Based on the data collected by the underground optical fiber sensor, the data is analyzed and provided <span style="color:#FF6400;">heat map display, leakage point prediction and other functions</span>.You can go to the computer to experience the free functions!
                    <br>Visit link(PC version): <br><span style="border-bottom: #333333 1px solid">https://www.oilgasgpts.com/fiber</span><br>
                    If you have any cooperation or product consultation needs, please <span style="color:red" @click="clickConsult('Oil & Gas Generic AI ')">contact us</span>！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>

    </div>
  </div>


</div>
</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";

import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vLeftIn} from '@/utils/vLeftIn.js'
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
      changeVisable:1,
      title:"",
      model:false,
      pageTitle: 'Fiber Optic Wellbore Leakage Point Real-time Prediction Platform-Explanatory GPTs-Oil & Gas Generic AI',
      pageDescription: ' The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. ',
      pageKeywords: 'Fiber optic wellbore leakage point real-time prediction platform，Explanatory GPTs，Oil & Gas Generic AI',
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      activeName: 'first',
      userId:localStorage.getItem("userId"),
      iframeUrl1:"https://ab.oilgasgpts.com/fiberwebEn",
      iframeUrl2:"https://ab.oilgasgpts.com/fiberwebEn/#/dts",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",

    }

  },

  methods: {
    freeExperience(){
      // 获取 ref 为 'freeplay' 的元素
      const freeplaySection = this.$refs.freeplay;
      // 使用 scrollIntoView 定位到该元素
      freeplaySection.scrollIntoView({ behavior: 'smooth' });
    },
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       const element = this.$refs.totalModel;
       const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       this.$nextTick(() => {
         this.$refs.totalModel.scrollTop = rect
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          const element = this.$refs.total;
          const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          this.$nextTick(() => {
            this.$refs.total.scrollTop = rect
          });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     changeheadVisable(){
       if(this.changeVisable===1){
         this.changeVisable=0
       }
       else{
         this.changeVisable=1
       }
       console.log("父组件", this.changeVisable)
     },
    handleScroll() {
      this.scrollHeight = this.$refs.total.scrollTop;


      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 350) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {

    localStorage.setItem("home",0)
    let url = window.location.href
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Fiber Optic Wellbore Leakage Point Real-time Prediction Platform-Explanatory GPTs-Oil & Gas Generic AI',
        pageDescription: ' The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. ',
        pageKeywords: 'Fiber optic wellbore leakage point real-time prediction platform，Explanatory GPTs，Oil & Gas Generic AI',
      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }

  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/gpt6.jpg");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;

      .layer2 {
        margin-top: 10vh;
        margin-left: 2vw;

        .title1 {
          width: fit-content;
          //height: 7.77626699629172vh;
          font-size: 2.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 2.63733003708282vw;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.8vw;
        }

        .title4 {
          margin-top: 3vh;
          width: 40vw;
          font-size: 1vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          line-height: 7vh;
        }

        .titlle3 {
          position: absolute;
          bottom: 7.78739184177998vh;
          margin-top: 4.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: bolder;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 0px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          //border: #2168DB 1px solid;
          background: orange;
          //background: rgba(255, 255, 255, 0.5);
        }
      }
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1.4vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .screenmake0 {
      padding: 0.8vh 9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }

      ::v-deep .el-tabs__nav {
        text-align: center;
        float: none;
      }

      ::v-deep .el-tabs__item {
        width: 50%;
        font-size: 1.2vw !important;
        padding: 2vh 5vh;
        height: fit-content;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #316fff;
      }

      ::v-deep .el-tabs__header {

        margin: 0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #316fff;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 85vh;;
            overflow: hidden;

            .iframeStyle {
              background-color: #ececec;
              margin: -18vh 0 0 -9.8vw;
              width: 100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }

            .iframeStyle iframe {
              background-color: #ececec;
              width: 100vw;
              min-height: 200vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }

            .imgDesign {

              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 2vh 3vw;
              width: 100%;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;

              .Title1 {

                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: bold;
                line-height: 1.2vw;
              }

              .Title2 {
                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }

    .screenmake1 {
      padding: 6.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }
      .grid {
        margin-top: 2.78739184177998vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back {
            border-radius: 9px;
            position: relative;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 47vh;
            padding: 0 0 3vh 0;
            overflow: hidden;
            .imgDesign {
              height: 8vh;
              width: 8vh;
              margin: 2vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign {
              margin: 0 1vw;
              .Title1{
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1.4vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              .Title2{
                margin:auto 1.5vw;
                color:#111111;
                //text-align: justify;
                font-size: 1.2vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 92%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .titleDesign{
            z-index: 999;
            padding: 0;
            margin:0 0 0 1.5vw;
            color: black;
            width: 100%;
            float: right;
            .title1{
              margin: 1vh auto;
              display: flex;
              flex-direction: column;
              justify-content: center; /* Center vertically */
              align-items: center;
              color:#111111;
              text-align: center;
              font-size: 1.4vw;
              height: fit-content;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 4.7194066749vh;
            }
            .title2{
              margin:auto 0.5vw;
              color:#111111;
              //text-align: justify;
              font-size: 1.2vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 3vh;
            }
            .title3{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);

              margin-top: 2vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }

          }
          .back         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:51vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;
          }
          .back1         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:51vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
          .back2         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:51vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
          .back3         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:51vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:18.5vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 650px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel71.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color: #fff;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: white;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;
      .title1 {
        padding:0 15px;
        margin:25px auto;
        width: fit-content;
        text-align: center;
        height: fit-content ;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height:26PX;
      }

      .title2 {
        margin: 25px auto 0 auto;
        width: 90%;
        text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
      .title3 {
        margin: 18px auto;
        width: 80%;
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 18px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {

      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Times New Roman";
              font-weight: 700;
            }

          }


          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }

          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }

  .screenmake2{
    //z-index:: 10;

    //left: 10.86077411900635vw;
    width: fit-content;
    height: fit-content;
    padding:20px 0;
    background: transparent;
    .heighLight{
      text-align: center;
      height:18px;
      opacity: 1;
      font-size: 18px;
      //color: #53A3F7;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 15px;
      grid-template-columns:  minmax(0,1fr);
      .card{
        //cursor: pointer;
        width: 100%;
        height:fit-content ;
        padding:0 0.4vw 0vh 0.69324090121317vw;
        opacity: 1;
        .title1{
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
        .title2{
          margin:5px 0;
          line-height: 22px;
          font-size: 14px;
        }
        .title3{
          margin-top: 5px;
          display: grid;

        }
        .titleDesign{
          padding: 0 10px 10px 10px ;
          margin-top: 5px;
        }
        .back{
          background: white;
          border-radius: 9px;
          position: relative;
          height:fit-content;
          margin-top: 10px;
          overflow:hidden;
          .imgDesign{
            width: 45px;
            height:45px;
            margin: 0 auto 0px auto;
            opacity: 1;
            //background-image: url("../../assets/menage6.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }


        }

      }

      ::v-deep .el-carousel__container{
        height: 65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        //cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake21{
    width: 100vw;
    height: fit-content;
    margin: 20px 0px;
    padding:0  4.6875vw 0px 4.6875vw;
    background: white;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 10px;
      grid-template-columns: minmax(0,1fr) ;
      column-gap: 5px;
      .card{
        cursor: pointer;
        width: 100%;
        height:fit-content ;
        display: flex;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh ;
        opacity: 1;
        .title1{
          margin: 5px auto;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
        }
        .title2{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .title3{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .titleDesign{
          width: 90%;
          margin: 0 0 0 15px;
        }
        .back{
          //background: white;
          border-radius: 9px;
          column-gap: 5px;
          position: relative;
          padding: 0 10px 0 10px;
          height:fit-content;
          display: flex;
          overflow:hidden;
          .imgDesign{
            width: 45px;
            height:45px;
            margin:0 auto 15px auto;
            opacity: 1;
          }


        }

      }

      ::v-deep .el-carousel__container{
        height: 65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake6 {

    //z-index:: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding: 20px 4.6875vw;
    background: white;

    .heighLight {
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 18px;
      //color: #53A3F7;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 22px;
    }

    .heightLight2 {
      margin: 19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .grid {
      position: relative;
      height: fit-content;

      .card {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
        opacity: 1;

        .title1 {
          margin: 5px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
        }

        .title2 {

          width: fit-content;
          margin: 2vh;
          line-height: 22px;
          font-size: 14px;
        }

        .titleDesign {
          margin-top: 20px;
        }

        .title3 {
          margin-top: 5px;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          line-height: 22px;
          font-size: 14px;
        }

        .back {
          margin-top: 15px;
          height: fit-content;
          background: #EFF5FF;
          border-radius: 9px;
          position: relative;
          box-shadow: 2px 0 12px 0px #c9d9f5;
          //height:580px ;
          overflow: hidden;

          .imgDesign {
            width: 100%;
            height: 195px;
            opacity: 1;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }


        }

      }

      ::v-deep .el-carousel__container {
        height: 65.87268232385661vh !important;
      }

      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }

      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }

    .grid1 {
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 25px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2vw;
      row-gap: 15px;

      .card {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        height: 185px;
        background-color: white;
        padding: 5px 10px;
        opacity: 1;

        .title1 {
          height: 45px;
          justify-content: center;
          align-items: center;
          display: flex;
          font-size: 16px;
          font-weight: bold;
        }

        .title2 {
          margin: 0 5px;
          text-align: justify;
          line-height: 22px;
          font-size: 14px;
        }

        .titleDesign {
          margin-top: 10px;
        }

        .title3 {
          margin-top: 5px;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          line-height: 22px;
          font-size: 14px;
        }

        .back {
          margin-top: 15px;
          border-radius: 9px;
          position: relative;
          //height:580px ;
          overflow: hidden;

          .imgDesign {
            width: 40px;
            height: 38px;
            margin: 0 10px 0 5px;
            opacity: 1;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }


        }

      }

      ::v-deep .el-carousel__container {
        height: 65.87268232385661vh !important;
      }

      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }

      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;


    padding: 20px 15px;
    background: black;

    .heighLight {
      text-align: center;
      height: 21px;

      opacity: 1;
      font-size: 18px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;

        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
