<template>
  <div>
    <div v-if="model===false">   <div class="totalmakeHole" ref="total">
      <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
        <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
      </div>
      <div class="makeHole1" @click="changeheadVisable">
        <kefu @toptop="topTop"></kefu>
<!--          <div class="background">-->
<!--             -->
<!--          <div v-zoom-in="{duration:800}" class="titlle1">Intelligent Fracturing Solutions</div>-->
<!--          <div v-zoom-in="{duration:800}" class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>-->
<!--          <div v-zoom-in="{duration:800}" class="titlle3" @click="dialogVisible=true">-->
<!--            Solution Consulting-->
<!--          </div>-->

<!--        </div>-->
        <subordianteheader :imagepath="'2'" :title1="'Intelligent Fracturing Solutions'" :title2="'*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.'" @dialogVisible="dialogVisible=true"></subordianteheader>
        <div class="screenmake0">
          <div v-sliden-in="{duration:800}" class="heighLight">Intelligent Fracturing Services Overview</div>
          <div v-sliden-in="{duration:800}" class="heightLight2">Using geological engineering integration, we shift from traditional iterative fracturing to intelligent fracturing, leveraging cloud and edge computing to optimize services, minimize risks, and improve modification effectiveness.
          </div>
            <div class="grid">
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img src="@/assets/frack01.png">-->
                </div>
                <div class="titleDesign">
                  <div  class="Title1">Anton real-time iterative service technology</div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card2">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img src="@/assets/frack02.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">Anton Intelligent Fracturing System</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake21" style="padding:0 11.0976314269vw  7.7873918418vh">
          <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 1.7vw;font-weight: unset">  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The Risk of Sand Plugging is Difficult to Predict in Advance
                  </div>
                  <div class="title2">
                    In fracturing operations, it is difficult to predict the risk of sand plugging in advance, which may lead to construction failure, production interruption or well loss.
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:1200}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The Prediction of EUR Production is Inaccurate
                  </div>
                  <div class="title2">
                    It is impossible to accurately predict the EUR production in the same well area, affecting the return on investment and the rationality of the development plan.
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:1600}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">It is Difficult to Select Fracturing Construction Parameters
                  </div>
                  <div class="title2">
                    Due to the complex geological conditions in different well areas, the selection of fracturing construction parameters relies on experience or multiple tests, increasing time and resource costs.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
            <span>&nbsp;&nbsp;Intelligent Optimization Of Fracturing Parameters</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">Based on vast historical single-well data and big data models, we establish mapping relationships among geological parameters, engineering parameters, and EUR, enabling intelligent optimization of fracturing design parameters.
          </div>
            <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Schematic diagram of fracturing construction parameter optimization</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title">Relationship between cluster spacing and EUR under different permeability conditions</div>
                </div>
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">Differentiated Intelligent Segmentation</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
            <span>&nbsp;&nbsp;Real-time Sand Plugging Risk Early WarningFor Fracturing</span>
          </div>
          <div v-sliden-in="{duration:800}" class="Light">Establish risk prediction model to monitor the risk of sand plugging in real time and optimize the fracturing parameters to reduce the chance of sand plugging.</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Construction pressure prediction and sand plugging risk warning system</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Anton Fracturing Remote Monitoring and Real-Time Analysis System for Sand Plugging Early Warning</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span style="margin-top:0.5vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
            <span>&nbsp;&nbsp;Intelligent Drilling Engineering</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">
            Based on large models, we establish mapping relationships between implicit engineering features and EUR, as well as engineering parameters, to optimize construction parameters in real time.
          </div>
            <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Real-time iterative optimization site based on technical team</div>

                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Real-time control of fracturing effect</div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="screenmake4">
          <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div v-left-in="{duration:800}" class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div v-sliden-in="{duration:800}" class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1"  style="margin-top: 25px">{{item.title2}}</div>
                      <div class="title2">
                        {{item.title3}}

                      </div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <bottomTip></bottomTip>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
    <div v-else>   <div class="totalmakeHoleModel">
      <kefuMobile @topMobile="topTopmobile"></kefuMobile>
      <div class="makeHole1">
<!--        <div class="background">-->
<!--          &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->
<!--          <headtip @showDialog="showPartner"></headtip>-->
<!--          &lt;!&ndash;     文字&ndash;&gt;-->
<!--          <div v-zoom-in="{duration:800}" class="titlle1">Intelligent Fracturing Solutions</div>-->
<!--          <div v-zoom-in="{duration:800}" class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>-->
<!--          <div class="titlle3" @click="dialogVisible=true">-->
<!--            Solution Consulting-->
<!--          </div>-->
<!--        </div>-->
        <sub-header-mobile :imagepath="'/gptModel31.png'" :back-height="700" :title1="'Intelligent Fracturing Solutions'" @clickbtn1="showPartner" @clickbtn2="dialogVisible=true" ></sub-header-mobile>
        <div class="screenmake0">
          <div v-sliden-in="{duration:800}" class="heighLight">Intelligent Fracturing Services Overview</div>
          <div v-sliden-in="{duration:800}" class="heightLight2">Based on the integration of geology and engineering, the company has transformed from traditional empirical iterative fracturing to intelligent fracturing, provided intelligent fracturing technical services through cloud platform + edge computing, fully explored the potential of massive historical data, improved the timeliness and pertinence of parameter optimization, maximized the control of fracturing risks, and enhanced the effect of fracturing reform.</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img src="@/assets/frack01.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">Anton real-time iterative service technology</div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card2">
              <div class="back">
                <div class="imgDesign">
                  <!--                <img src="@/assets/frack02.png">-->
                </div>
                <div class="titleDesign">
                  <div class="Title1">Anton Intelligent Fracturing System</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake21" >
          <div v-sliden-in="{duration:800}" class="heighLight">  Business Pain Points and Challenges</div>
          <div class="line"></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/risk.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The Risk of Sand Plugging is Difficult to Predict in Advance
                  </div>
                  <div class="title2">
                    In fracturing operations, it is difficult to predict the risk of sand plugging in advance, which may lead to construction failure, production interruption or well loss.
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/prediction.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The Prediction of EUR Production is Inaccurate
                  </div>
                  <div class="title2">
                    It is impossible to accurately predict the EUR production in the same well area, affecting the return on investment and the rationality of the development plan.
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">It is Difficult to Select Fracturing Construction Parameters
                  </div>
                  <div class="title2">
                    Due to the complex geological conditions in different well areas, the selection of fracturing construction parameters relies on experience or multiple tests, increasing time and resource costs.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span>①&nbsp;Intelligent Optimization Of Fracturing Parameters</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">Based on the massive single-well historical data, establish the mapping relationship between geological parameters, engineering parameters and EUR based on big data model, intelligently evaluate the matching between engineering parameters and formation with the optimal EUR as the core, and realize the intelligent optimization of fracturing design parameters.</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Schematic diagram of fracturing construction parameter optimization</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title">Schematic diagram of fracturing construction parameter optimization</div>
                </div>
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">Differentiated Intelligent Segmentation</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span>②&nbsp;Real-time Sand Plugging Risk Early WarningFor Fracturing</span>
          </div>
          <div class="Light">Establish risk prediction model to monitor the risk of sand plugging in real time and optimize the fracturing parameters to reduce the chance of sand plugging.</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Construction pressure prediction and sand plugging risk warning system</div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Anton Fracturing Remote Monitoring and Real-Time Analysis System for Sand Plugging Early Warning</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div class="screenmake3">
          <div v-sliden-in="{duration:800}" class="heighLight">
            <span>③&nbsp;Intelligent Drilling Engineering</span>
          </div>

          <div v-sliden-in="{duration:800}" class="Light">Establish the mapping relationship between hidden engineering features and EUR based on the big model, clarify the optimal direction of one or several groups of key hidden engineering features, establish the mapping relationship between hidden engineering features and engineering parameters based on the big model, and optimize the construction parameters in real time according to the mapping relationship between hidden engineering features and EUR.</div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Real-time iterative optimization site based on technical team</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Real-time control of fracturing effect</div>
                  <div class="Title2"></div>
                </div>
              </div>


            </div>

          </div>

        </div>
        <div class="screenmake4">
          <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div v-zoom-in="{duration:800}" class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div v-zoom-in="{duration:800}" class="titleDesign">
                      <div class="title1">{{item.title1}}</div>
                      <div class="title1">{{item.title2}}</div>
                      <div class="title2">
                        {{item.title3}}

                      </div>

                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <resultTip
          :dialogVisible="dialogVisible"
          @hideDialog="hideDialog"
      ></resultTip>
    </div></div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import resultTip from "@/components/ResultTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import subordianteheader from "@/views/subordinate/components/subordianteheader.vue";
import SubHeaderMobile from "@/views/subordinate/components/subHeaderMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
  },
  name: "",
  props: [],
  components: {SubHeaderMobile, subordianteheader, PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
        changeVisable:1,
      pageTitle: 'Intelligent fracturing solution_GPT application project service for Oil & Gas development operations-Oil & Gas Communication GPT',
      pageDescription: 'Based on the integration of geology and engineering, the company has transformed from traditional empirical iterative fracturing to intelligent fracturing, provided intelligent fracturing technical services through cloud platform + edge computing, fully explored the potential of massive historical data, improved the timeliness and pertinence of parameter optimization, maximized the control of fracturing risks, and enhanced the effect of fracturing reform.',
      pageKeywords: 'Intelligent fracturing, Anton real-time iteration service technology, Anton intelligent fracturing system, intelligent optimization of fracturing parameters, real-time fracturing sand plugging risk warning, intelligent drilling engineering, Oil & Gas development operation GPT, Oil & Gas communication GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,

      success:[{
        img:require("@/assets/success3.png"),
        title1:"Fracturing Program Design GPT",
        title2:"Fracturing correlation analysis",
        title3:"Based on geological, engineering and production multivariate data, the main controlling factors are clarified through clustering, dimensionality reduction and multi-factor comprehensive evaluation.",
      },{
        img:require("@/assets/success4.png"),
        title1:"Fracturing Program Design GPT",
        title2:"EUR Prediction",
        title3:"Intelligent optimization of engineering parameters based on weight assignment of main control factors with optimal EUR as the core.",
      },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight); if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Intelligent fracturing solution_GPT application project service for Oil & Gas development operations-Oil & Gas Communication GPT',
        pageDescription: 'Based on the integration of geology and engineering, the company has transformed from traditional empirical iterative fracturing to intelligent fracturing, provided intelligent fracturing technical services through cloud platform + edge computing, fully explored the potential of massive historical data, improved the timeliness and pertinence of parameter optimization, maximized the control of fracturing risks, and enhanced the effect of fracturing reform.',
        pageKeywords: 'Intelligent fracturing, Anton real-time iteration service technology, Anton intelligent fracturing system, intelligent optimization of fracturing parameters, real-time fracturing sand plugging risk warning, intelligent drilling engineering, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/menage1.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 0.9vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:45vh;
              width: 100%;
              background-image: url("../../assets/frack01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1{
                color: black;
                text-align: center;
                font-size: 1vw;

                 font-family: 'Times New Roman';
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }
        .card2{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              text-align: center;
              height:45vh;
              //border:1px solid #2537a8;
              width: 100%;
              background-image: url("../../assets/frack02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5.5248454883vh 1.5248454883vw;
              //background-color: #2F6EFF;
              .Title1{
                color: black;
                text-align: center;
                font-size: 1vw;

                 font-family: 'Times New Roman';
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }
      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 3.5vh;
        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height: 39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5.8124845488vh 2.5597920277vw 5.1124845488vh 2.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7vh;
              }
              .Title2{
                text-align: center;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height:3.194066749vh;
              }
            }
          }
        }

        .card1{
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          // cursor: pointer;
          opacity: 1;

          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF;
            .imgDesign{
              margin:0 auto 0 auto;
              width: 80%;
              height:14.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            } .imgDesign1{
                width: 100%;
                height:14.06056860321384vh;
                opacity: 1;
                background-image: url("../../assets/frack31.png");
                background-position: center;
                background-size:cover;
                background-repeat: no-repeat;
                background-color: transparent;
              }
            .titleDesign{
              padding:1vh 2.5597920277vw 1.1124845488vh 2.5597920277vw;
              .Title1{
                margin: 5.5124845488vh auto;
                text-align: center;
                height: 2.7194066749vh;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title{
                text-align: center;
                margin: 2.5vh 0 2vh 0;
                height: 4.6971569839vh;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 3.194066749vh;
              }
              .Title2{
                text-align: center;
                margin: 5.5124845488vh auto;
                height: fit-content;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: white;
            .imgDesign{
              width: 100%;
              height: 36.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: white;
            .imgDesign{
              width: 100%;
              height: 36.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height: fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 3.5vh;
        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height:39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5.2124845488vh 2.5597920277vw 5.1124845488vh 2.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin: 8.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: center;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            overflow:hidden;
            background: #FFFFFF;
            .imgDesign{
              width: 100%;
              height:39.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/frack7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{

                text-align: center;
                height: fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin: 8.5vh 2.5597920277vw auto 2.5597920277vw;
                text-align: center;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }


      }
    }

    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto 3vh auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size: 1vw;
              }

              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 3vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel31.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size:12px;
         font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-75px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack01.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card2{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack02.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  #ebf1fd;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/frack2.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          height:fit-content ;
          padding:10px;
          // cursor: pointer;
          opacity: 1;

          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              margin:0 auto 0 auto;
              width: 100%;
              height:95px;
              opacity: 1;
              background-image: url("../../assets/frack3.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            } .imgDesign1{
                width: 100%;
                height:78px;
                opacity: 1;
                background-image: url("../../assets/frack31.png");
                background-position: center;
                background-size:cover;
                background-repeat: no-repeat;
                background-color: transparent;
              }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                text-align: center;
                margin-top: 2.5124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }




        }


      }
    }
    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:  white;;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height: fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
       }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:200px;
              width: 100%;
              background-image: url("../../assets/frack4.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:180px;
              width: 100%;
              background-image: url("../../assets/frack5.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: fit-content;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }

    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/frack6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: white;
            margin-bottom: 15px;
            .imgDesign{
              text-align: center;
              //border:1px solid #2537a8;
              height:215px;
              width: 100%;
              background-image: url("../../assets/frack7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
                height: 20px;
                font-size:12px;
                 font-family: 'Times New Roman';
               font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:400px;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -90px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size:12px;
        }
      }
    }
  }

}
</style>
