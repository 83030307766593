<script >
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vLeftIn} from "@/utils/vLeftIn";
export default {
  // props:['title1' ]
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  props: {
    title1: {
      type: String,
      default: 'Intelligent Reservoir Geology Solutions' // 设置默认值
    },
    title2:{
      type:String,
      default: '*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.'
    },
    imagepath:{
      type:String,

      default: '1'


    }
  },
  data(){
    return{
      image:"",
      imgurl:""
    }
  },
  methods:{
    showDialog() {
      this.$emit('dialogVisible', true); // 传递 true 表示 dialogVisible 打开
    }
  },
  // created() {
  //   this.image=require(this.imagepath)
  // },
  computed:{
    imgurlpath(){
      return require(`${this.imagepath}`)
    },
    backgroundStyle(){
      let imageUrl=""

      switch (this.imagepath){
        case '1':
          imageUrl = require(`../../../assets/peixunBannera.png`);
          break;
        case '2':
          imageUrl = require(`../../../assets/constructBannera.png`);
          break;
      }
      return{
        // this.imgurl=
        // backgroundImage:`url(${this.imagepath})`
        backgroundImage:`url(${imageUrl})`
        // backgroundImage:`url(${imageUrl}), linear-gradient(to bottom, #d5dcef 0%, #dfe6f3 60.1%, #cad6ed 40%, #cfd9ee 100%)`
      }
    }
  }
}
</script>

<template>
  <div class="background" :style="backgroundStyle" >
    <!--  <div class="background" :style="{backgroundImage:  `url(${this.imagepath})`}">-->
    <!--     文字-->
    <div class="layer2">


      <div v-sliden-in="{duration:800}" class="title1" style="font-weight: bolder;color:black;height: fit-content" v-html="title1">

      </div>
      <div v-sliden-instep="{duration:1200}" class="title4" style="font-weight: bolder;margin-top: 3vh;color:black;line-height: 7vh" v-html="title2">

      </div>
      <div class="title3" @click="showDialog" >
        Product Inquiry
      </div>
    </div>
  </div>
</template>

<style scoped>

.background{
  position: relative;
  padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
  width: 100%;
  height: 72.41285537700865vh;
  //height: 72.41285537700865vh;

  //background-color: blue;
  background-position: right center; /* 靠右对齐 */
  background-size: auto 100%; /* 左侧填充 */
  //background-position: center;
  //background-size:cover;
  background-repeat: no-repeat;
  background-color: #cbd6ed;
  .layer2 {
    margin-top: 4vh;
    margin-left:2vw;
    .title1 {
      font-weight: bolder;color:#282d7d;line-height: 6vh;
      width: fit-content;
      height: 7.77626699629172vh;
      font-size: 2.3vw;
      font-family:'Times New Roman';
    }
    .title4 {
      font-weight: bolder;
      margin-top: 7vh;
      color:#282d7d;
      line-height: 4.5vh;

      width: 40vw;
      height: fit-content;
      text-align: left;
      font-size: 1.3vw;
      font-family:'Times New Roman';
    }
    .title3{
      position: absolute;
      bottom: 7.78739184177998vh;
      margin-top: 4.07911001236094vh;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2vw;
      font-family: 'Times New Roman';
      font-weight: bolder;
      color: #ffffff;

      line-height: 3.3374536465vh;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 2.4624227441vh 1.1989601386vw;
      height: 4.5624227441vh;
      background: #2f6eff;
      border: #2f6eff 1px solid;



    }
    .title3:hover{

      color: #2168DB;
      border: #2168DB 1px solid;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  /* Add any additional styling as needed */

}


</style>
