<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="screen1">
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <kefu @toptop="topTop"></kefu>
          <!--      平台大图-->
        </div>
        <div class="screen2">
          <div class="title1"> Privacy policy</div>
          <div class="title2">
             <span>Welcome to the Oil & Gas GPT Cloud Platform (hereinafter referred to as “the <span style="font-weight: bold">Site</span>” or “<span style="font-weight: bold">we</span>”)!<br>
               We take your privacy seriously and are committed to protecting your personal information. The purpose of this Privacy Policy is to explain how we collect, use, share and protect your personal information and how you can manage it. Please read this Privacy Policy carefully before using this website.<br></span>

            <span style="font-size: 1.5vw;line-height: 8vh;">How We Collect Your Personal Information</span><br>
            <span style="font-weight: bold;">User-Submitted Information</span>: When you register for an account, fill out a form, participate in a survey, submit feedback, or engage in other interactions on the Site, we may collect your name, e-mail address, telephone number, company information, and other information that you voluntarily provide.<br>
            <span style="font-weight: bold;">Information Collected Automatically</span>: We use technology and third-party services (such as cookies and similar technologies) to automatically collect information about your visit to and use of the Site, including, but not limited to, your IP address, browser type, time of visit, pages visited, and how you interact with the Site.<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">How We Use Your Personal Information<br></span>
            <span style="font-weight: bold;">Provision of Services</span>: We use the information we collect to provide, maintain and improve our services, including verifying your identity, processing your requests, and responding to your inquiries and concerns.<br>
            <span style="font-weight: bold;">Personalize Your Experience</span>: We may use the information we collect to customize your experience on the Website by displaying relevant content, recommendations, and services.<br>
            <span style="font-weight: bold;">Analytics</span>: We use the information we collect to analyze site usage to understand user behavior and optimize site performance and functionality.<br>
            <span style="font-weight: bold;">Communications</span>: We may use your contact information to send you information about updates to the Site, changes to our services, promotions, or other information that we think you may find interesting (unless you have opted out of receiving such information).<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">How We Share Your Personal Information<br></span>
            <span style="font-weight: bold;"> Service Providers</span>: We may share your information with third-party service providers in order for them to provide us with technical support, data analytics, marketing services, or other necessary services. These service providers will be bound by confidentiality agreements and will only use your information for the purposes specified.<br>
            <span style="font-weight: bold;"> Legal Requirements</span>: We may disclose your information to comply with applicable laws, regulations, court orders or governmental requests.<br>
            <span style="font-weight: bold;"> Business Transfers</span>: In connection with a merger, acquisition or other business transfer, we may transfer your information to the new owner or controller.<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">Information Security<br></span>
            We take reasonable physical, electronic and managerial measures to protect your information from unauthorized access, use, disclosure, modification, loss or destruction. Please note, however, that despite our efforts to protect your information, no security measure is perfect and therefore we cannot fully guarantee the security of your information.<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">Use of SSL Protocol on This Website<br></span>
            In order to ensure the security of your personal information during transmission, this website uses the SSL (Secure Sockets Layer) protocol for data encryption, which ensures that data remains encrypted during transmission between the client and the server, thus preventing data from being intercepted or altered by unauthorized third parties.<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">Your Rights<br></span>
            <span style="font-weight: bold;">Access and Correction</span>: You have the right to access and correct the personal information we hold about you. To exercise this right, please contact us using the contact details provided in this Privacy Policy.<br>
            <span style="font-weight: bold;">Deletion</span>: You have the right to ask us to delete your personal information, subject to legal requirements. Please note that some information may need to be retained for legal, contractual or other legitimate reasons.<br>
            <span style="font-weight: bold;">Objection</span>: You have the right to object to our processing of your personal information, in particular where we are processing it on the basis of legitimate interests and not on the basis of your consent.<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">Contact<br></span>

            If you have any questions, suggestions or complaints about this Privacy Policy, please contact us at：<br>
            <span style="font-weight: bold;"> E-mail</span>: <a href="mailto:service@oilgasgpts.com">service@oilgasgpts.com</a><br>
            <span style="font-weight: bold;">  Mailing Address</span>: No. 8 Cuiping West Road, Donghu Street, Chaoyang District, Beijing, China<br>
            <span style="font-size: 1.5vw;line-height: 8vh;">Changes to This Privacy Policy<br></span>
            We may update this Privacy Policy from time to time to reflect changes in our business practices, legal requirements or other factors. Any changes will be posted on the Site and reasonable notice will be provided before the changes take effect. Please check this Privacy Policy periodically for the latest information.<br>
            Please continue to read and understand this Privacy Policy in its entirety to ensure that you have a full understanding of how your personal information is handled on this website. If you have any questions or require further information, please do not hesitate to contact us.<br>


          </div>



        </div>


        <!--底部-->
        <div class="screen7">
          <bottomTip></bottomTip>
        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1">
          <headtip @showDialog="showPartner"></headtip>
<!--          <kefuMobile @topMobile="topTopmobile"></kefuMobile>-->
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
        <div class="up" v-if="scrollTrue===false" @click="uptop"></div>
<!--        <div class="feedback"  @click="feedback">Feed-<br>back</div>-->
        <div class="screen2" >
          <div class="title1"> Privacy policy</div>
          <div class="title2">
             <span>Welcome to the Oil & Gas GPT Cloud Platform (hereinafter referred to as “the <span style="font-weight: bold">Site</span>” or “<span style="font-weight: bold">we</span>”)!<br>
               We take your privacy seriously and are committed to protecting your personal information. The purpose of this Privacy Policy is to explain how we collect, use, share and protect your personal information and how you can manage it. Please read this Privacy Policy carefully before using this website.<br></span>

            <span style="font-size: 16px;line-height: 32px;">How We Collect Your Personal Information</span><br>
            <span style="font-weight: bold;">User-Submitted Information</span>: When you register for an account, fill out a form, participate in a survey, submit feedback, or engage in other interactions on the Site, we may collect your name, e-mail address, telephone number, company information, and other information that you voluntarily provide.<br>
            <span style="font-weight: bold;">Information Collected Automatically</span>: We use technology and third-party services (such as cookies and similar technologies) to automatically collect information about your visit to and use of the Site, including, but not limited to, your IP address, browser type, time of visit, pages visited, and how you interact with the Site.<br>
            <span style="font-size: 16px;line-height: 32px;">How We Use Your Personal Information<br></span>
            <span style="font-weight: bold;">Provision of Services</span>: We use the information we collect to provide, maintain and improve our services, including verifying your identity, processing your requests, and responding to your inquiries and concerns.<br>
            <span style="font-weight: bold;">Personalize Your Experience</span>: We may use the information we collect to customize your experience on the Website by displaying relevant content, recommendations, and services.<br>
            <span style="font-weight: bold;">Analytics</span>: We use the information we collect to analyze site usage to understand user behavior and optimize site performance and functionality.<br>
            <span style="font-weight: bold;">Communications</span>: We may use your contact information to send you information about updates to the Site, changes to our services, promotions, or other information that we think you may find interesting (unless you have opted out of receiving such information).<br>
            <span style="font-size: 16px;line-height: 32px;">How We Share Your Personal Information<br></span>
            <span style="font-weight: bold;">Service Providers</span>: We may share your information with third-party service providers in order for them to provide us with technical support, data analytics, marketing services, or other necessary services. These service providers will be bound by confidentiality agreements and will only use your information for the purposes specified.<br>
            <span style="font-weight: bold;">Legal Requirements</span>: We may disclose your information to comply with applicable laws, regulations, court orders or governmental requests.<br>
            <span style="font-weight: bold;">Business Transfers</span>: In connection with a merger, acquisition or other business transfer, we may transfer your information to the new owner or controller.<br>
            <span style="font-size: 16px;line-height: 32px;">Information Security<br></span>
            We take reasonable physical, electronic and managerial measures to protect your information from unauthorized access, use, disclosure, modification, loss or destruction. Please note, however, that despite our efforts to protect your information, no security measure is perfect and therefore we cannot fully guarantee the security of your information.<br>
            <span style="font-size: 16px;line-height: 32px;">Use of SSL Protocol on This Website<br></span>
            In order to ensure the security of your personal information during transmission, this website uses the SSL (Secure Sockets Layer) protocol for data encryption, which ensures that data remains encrypted during transmission between the client and the server, thus preventing data from being intercepted or altered by unauthorized third parties.<br>
            <span style="font-size: 16px;line-height: 32px;">Your Rights<br></span>
            <span style="font-weight: bold;">Access and Correction</span>: You have the right to access and correct the personal information we hold about you. To exercise this right, please contact us using the contact details provided in this Privacy Policy.<br>
            <span style="font-weight: bold;">Deletion</span>: You have the right to ask us to delete your personal information, subject to legal requirements. Please note that some information may need to be retained for legal, contractual or other legitimate reasons.<br>
            <span style="font-weight: bold;">Objection</span>: You have the right to object to our processing of your personal information, in particular where we are processing it on the basis of legitimate interests and not on the basis of your consent.<br>
            <span style="font-size: 16px;line-height: 32px;">Contact<br></span>

            If you have any questions, suggestions or complaints about this Privacy Policy, please contact us at：<br>
            <span style="font-weight: bold;"> E-mail</span>: <a href="mailto:service@oilgasgpts.com">service@oilgasgpts.com</a><br>
            <span style="font-weight: bold;">  Mailing Address</span>: No. 8 Cuiping West Road, Donghu Street, Chaoyang District, Beijing, China<br>
            <span style="font-size: 16px;line-height: 32px;">Changes to This Privacy Policy<br></span>
            We may update this Privacy Policy from time to time to reflect changes in our business practices, legal requirements or other factors. Any changes will be posted on the Site and reasonable notice will be provided before the changes take effect. Please check this Privacy Policy periodically for the latest information.<br>
            Please continue to read and understand this Privacy Policy in its entirety to ensure that you have a full understanding of how your personal information is handled on this website. If you have any questions or require further information, please do not hesitate to contact us.<br>


          </div>



        </div>

        <!--底部-->
        <div class="screen7" ref="bottom">
          <bottomTip></bottomTip>
        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import technology from "@/views/Anxun/technology.vue";
import network from "@/views/Anxun/network.vue";
// import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";

export default {
  name: "Home",
  components: {
    kefu,
    bottomTip,
    PartnerTip,
    consultTip,
    headtip,

  },

  data() {
    return {

      scrollPosition: 0,
      model: false,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      title:'数智油田',
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight:0,
      scrollTrue:true,
    };
  },
  computed: {
    network() {
      return network
    },
    technology() {
      return technology
    },

  },
  created() {
    localStorage.setItem("home",1)


  },
  watch: {},
  // beforeRouteLeave(to, from, next) {
  //   // 保存滚动位置
  //   this.scrollPosition = this.$refs.totalModel.scrollTop;
  //   next();
  // },

  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用项目服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    }
    else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    }
    else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    }
    else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    }
    else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    }else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    }else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    }else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // const element = this.$refs.totalModel;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       })

    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      console.log("gaodu",this.scrollHeight<100)
    },

    uptop(){


      if(this.model===false){
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      }else{
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }
    },
    feedback(){
      window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
    Consult(item){
      console.log(item)
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground() {
      localStorage.setItem("skip", 0)
      window.open("/groundOil", "_blank");
    },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/pipeline", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/digital", "_blank");
    },
    traningAi(){
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    constructPlat(){
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },
    traningAi1(){
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1(){
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip",6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip",5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/pipeline")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/digital")
    },

    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },


    expo() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      window.open("https://aigc.yizhentv.com/", "_blank");
    },

    meitiProduct(){
      window.open("http://www.oilmates-omm.com/en/h-col-106.html", "_blank");
    },
    meiti3D(){
      window.open("http://www.oilmates-omm.com/en/h-col-102.html", "_blank");
    },
    meitiDesign(){
      window.open("http://www.oilmates-omm.com/en/h-col-104.html", "_blank");
    },
    meitiProduct1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meitiDesign1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://www.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://www.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },

    traning(){
      window.open("/traning", "_blank");
    },
    service(){
      window.open("/service", "_blank");
    },
    construct(){
      window.open("/construct", "_blank");
    },
    menageService(){
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application(){
      window.open("/application", "_blank");
    },
    oilApplication(){
      window.open("/oilApplication", "_blank");
    },

    knowledge(){
      window.open("/gptKnowledge", "_blank");
    },
    data(){
      window.open("/gptData", "_blank");
    },
    wisdom(){
      window.open("/wisdom", "_blank");
    },
    plat(){
      window.open("/plat", "_blank");
    },
    design(){
      window.open("/design", "_blank");
    },
    automation(){
      window.open("/automation", "_blank");
    },

    traning1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/traning")
    },
    service1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/service")
    },
    construct1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/construct")
    },
    menageService1(){
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/application")
    },
    oilApplication1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/oilApplication")
    },

    knowledge1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/gptKnowledge")
    },
    data1(){
      this.$router.push("/gptData")
      sessionStorage.setItem("skip",10)
    },
    wisdom1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/wisdom");
    },
    plat1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/plat")
    },
    design1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/design")
    },
    automation1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/automation")
    },

    position1(){
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position2(){
      const element = this.$refs.generalMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position3(){
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position4(){
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position5(){
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position6(){
      const element = this.$refs.consultMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position7(){
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position8(){
      const element = this.$refs.moveProduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position9(){
      const element = this.$refs.mediaMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position10(){
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },

    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },

    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: white;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 10vh;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;

    /* Add any additional styling as needed */

    .up {
      cursor: pointer;
      position: fixed;
      top: 90%;
      right: 2%;
      padding: 5px;
      width: 2.5vw;
      height: 2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .layer2 {
      margin-top: 8.1495673671199vh;
      text-align: center;

      .title1 {

        height: 8.77626699629172vh;
        font-size: 2.7606585788562vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 4.82076637824475vh;
        height: 8.52904820766378vh;
        font-size: 1.25979202772964vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: normal;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 3vh auto 0 auto;
        width: fit-content;
        height: 8.52904820766378vh;
        font-size: 0.97vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: normal;
        color: #2f6eff;
        line-height: 5.31520395550062vh;

        .hoverTitle {
          display: flex;
          margin-right: 1.5vw;
          cursor: pointer;
          transition: transform 0.3s ease-out;

        }

        .hoverTitle:hover {
          transform: translateY(-5px);
        }
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screen2 {
    z-index: 3;
    position: relative;
    width: 100%;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;


      .title1 {
        margin:0 auto;
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 2.7606585788562vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: 500;
        color: black;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin: 4.82076637824475vh auto;
        width: 65vw;
        font-size: 1.1vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: normal;
        color: black;
        line-height: 4vh;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 3vh auto 0 auto;
        width: fit-content;
        height: 8.52904820766378vh;
        font-size: 0.97vw;
        font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
        font-weight: normal;
        color: #2f6eff;
        line-height: 5.31520395550062vh;

        .hoverTitle {
          display: flex;
          margin-right: 1.5vw;
          cursor: pointer;
          transition: transform 0.3s ease-out;

        }

        .hoverTitle:hover {
          transform: translateY(-5px);
        }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .dashedlayer {
    z-index: 8;
    position: absolute;
    top: 92.21260815822002vh;
    left: 0;
    width: 15vw;
    height: 8.52904820766378vh;
    background: linear-gradient(to right, #e9effb 30%, transparent 100%);
  }

  .dashedlayerblue {
    z-index: 9;
    position: absolute;
    top: 91.21260815822002vh;
    left: 0;
    width: 80vw;
    height: 8.52904820766378vh;
    background: linear-gradient(
            to bottom,
            rgba(202, 222, 251, 0.9) 0%,
            transparent 100%
    );
  }

  .layer4 {
    z-index: 2;
    position: absolute;
    top: 92.21260815822002vh;
    left: 4.44829578278452vw;
    width: 89.42807625649913vw;
    height: 8.52904820766378vh;
    background-image: url("../assets/hummer.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .dashed {
    z-index: 1;
    position: absolute;
    top: 67.36711990111248vh;
    left: 10.86077411900635vw;
    width: 80.22068168688619vw;
    height: 75.24474660074166vh;
    background: linear-gradient(to bottom, #ebf1fd 50%, transparent 100%);
  }


}

.totalModel {
  background: white;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .up{
    cursor: pointer;
    position: fixed;
    top:90%;
    right:3%;
    padding:5px;
    width: 45px;
    height:45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .feedback{
    cursor: pointer;
    position: fixed;
    top:48%;
    font-size: 12px;
    z-index: 999999999999;
    right:0px;
    background-color: #bf0224;
    padding:5px;
    width: fit-content;
    height:fit-content;
    color: white;
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 50px;
    background-color: white;
    padding: 30px 2.6875vw;

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 8.23733003708282vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }
  .screen2 {
    z-index: 3;
    position: relative;
    width: 100%;
    padding: 15px 15px;


    .title1 {
      margin:0 auto;
      text-align: center;
      height: 35px;
      font-size: 16px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 500;
      color: black;
      line-height: 35px;
    }

    .title2 {
      margin: 15px auto;
      width: 95vw;
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: normal;
      color: black;
      line-height: 26px;
    }

    .title3 {
      display: flex;
      text-align: center;
      margin: 3vh auto 0 auto;
      width: fit-content;
      height: 8.52904820766378vh;
      font-size: 0.97vw;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: normal;
      color: #2f6eff;
      line-height: 5.31520395550062vh;

      .hoverTitle {
        display: flex;
        margin-right: 1.5vw;
        cursor: pointer;
        transition: transform 0.3s ease-out;

      }

      .hoverTitle:hover {
        transform: translateY(-5px);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }

}
</style>

