<template>

  <div class="totalmakeHole" ref="total">        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
    <div class="makeHole1">
      <div class="background">
        <!--      logo  you气通GPT社区 合作伙伴  登录-->
        <!--     文字-->
        <div class="titlle1">
          Intelligent Monitoring And Early Warning For Fiber Optic Leakage/Water Leakage Detection</div>
        <div class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. </div>
        <div class="title2">①&nbsp;Explanatory services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals </div>

        <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">
         Product Inquiry
        </div>
      </div>
      <div class="screenmake0">
        <div class="heighLight">DAS Analysis</div>

        <div class="grid">
          <div class="card">
            <div class="back">
              <div class="imgDesign">
                 <img loading="lazy" style="border-radius: 9px" src="@/assets/third18.png">

              </div>
              <div class="titleDesign">
                <div class="Title2">
                  Using thermoacoustic detection technology to carefully analyze the tiny differences in temperature and sound signals can effectively improve the accuracy of leak location. Even in complex environments, the leak can be quickly located to ensure timely repair and prevent the expansion of losses.
                </div>
              </div>


            </div>


          </div>

        </div>

      </div>
      <div class="screenmake1">
        <div class="heighLight">DTS Analysis</div>

        <div class="grid">
          <div class="card">
            <div class="back">

              <div class="titleDesign">
                <div class="Title2">
                  Based on detailed measurements of sound intensity and in-depth analysis of frequency band information, we can more comprehensively evaluate the shape and size of leaks and provide suggestions for repair solutions.
                </div>
              </div>
              <div class="imgDesign">
                 <img loading="lazy" style="border-radius: 9px" src="@/assets/third19.png">

              </div>

            </div>


          </div>

        </div>

      </div>
      <bottomTip></bottomTip>
    </div>

      <partnerTip
        :dialogVisible="dialogconsult"
        @hideDialog="hide"
    ></partnerTip>
    <consultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>

</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {

     return {
      changeVisable:1,
      title:'Oil & Gas Generic AI',
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

 methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
 const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },

    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     changeheadVisable(){
       if(this.changeVisable===1){
         this.changeVisable=0
       }
       else{
         this.changeVisable=1
       }
       console.log("父组件", this.changeVisable)
     },
   handleScroll() {
       this.scrollHeight = this.$refs.total.scrollTop;


     if (this.scrollHeight < 100) {
       this.scrollTrue = true
     } else {
       this.scrollTrue = false
     }
     if (this.scrollHeight < 350) {
       this.scrollback = false
     } else {
       this.scrollback = true
     }
     console.log("gaodu", this.scrollHeight < 100)
   },
 },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process GPTs_Oil & Gas industry process large model artificial intelligence application-Oil & Gas Communication GPT',
        pageDescription: 'The Oil & Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process, ensuring the safe and stable exploitation of Oil & Gas fields.',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering, intelligent injection and production decision-making, Oil & Gas field video monitoring, drilling risk warning, artificial lift optimization, mechanical production intelligent management and control, Oil & Gas field energy consumption optimization, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud Platform, Oil & Gas channel large model, Oil & Gas channel GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/third17.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1.4vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:6.8430160692vh  15.9763142692085vw ;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 4vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 5px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:45.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              height:100%;
              width:fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              display: flex;
              align-items: center; /* 垂直居中 */
              justify-content: center; /* 水平居中 */
              .Title2{
                margin:auto 0 auto 3vw;
                color:#111111;
                font-size: 1.2vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3vh;
                width: 30vw;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake1{
      padding:6.8430160692vh  15.9763142692085vw ;
      width: 100%;
      height:  fit-content;
      background: #D4E4FF;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 5px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:45.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              height:100%;
              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              display: flex;
              align-items: center; /* 垂直居中 */
              justify-content: center; /* 水平居中 */
              .Title2{
                align-items: flex-start;
                margin:3vh 1vw;
                color:#111111;
                //text-align: justify;
                font-size: 1.2vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3vh;
                width: 30vw;
              }
              li{
                margin-top:0.5vh;
              }
            }
          }
        }

      }
    }
  }

}

</style>
