<template>
  <div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>8
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>
    <div style="display: flex">
      <div v-zoom-in="{ duration:500}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:600}" style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:700}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:800}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:900}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
      <div v-zoom-in="{ duration:1000}"  style="height: 10vh; width: 10vw; background-color: red; margin: 5vh;">
        <!-- 这是需要应用滑入效果的内容 -->
      </div>
    </div>

  </div>
</template>

<script>
import { vSlidenIn,vZoomIn} from '../utils/vSlidenIn.js'; // 确保路径正确

export default {
  directives: {
      slidenIn: vSlidenIn,
      zoomIn:vZoomIn
  },
};
</script>

<style scoped lang="scss">
/* 你可以在这里添加样式 */
</style>
