<template>
  <div>
    <div v-if="model===false">
       <div class="totalmakeHole" ref="total">
      <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
        <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
      </div>

      <div class="makeHole1" @click="changeheadVisable">
        <div class="background" style="position: relative">
           <kefu @toptop="topTop"></kefu>
          <!--     文字-->
          <div class="layer2" style="height: 100%">
          <div class="title1" v-sliden-instep="{duration:800}" style="font-weight: bolder;color: #282d7d">Formation Pressure<br>Anomaly Alarm</div>

            <div class="title2" v-sliden-instep="{duration:1200}" style="font-weight: bolder;margin-left: 0px;color: #282d7d">
              <li>Monitor drilling parameters in real-time, calculate the DC index from drilling data to accurately predict formation pressure, and provide timely warnings for drilling anomalies</li>
            </div>
            <div style="display: flex;width: 30vw;margin: auto;margin-left: 0px;position: absolute;bottom: 0vh">
              <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto"  @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
              <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto" @click="freeExperience">
                Free Trial
              </div>
            </div>
          </div>

        </div>

        <div class="screenmake21" style="padding-top: 6vh">
          <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.1vw">  Business Pain Points and Challenges</div>
<!--          <div class="line"></div>-->
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Inaccurate or Untimely Prediction
                  </div>
                  <div class="title2">
                    Formation pressure is crucial in drilling engineering design, but if abnormal high or low pressure is not predicted in a timely manner, it can easily lead to well control hazards or lost circulation, jeopardizing drilling safety, increasing operational risks, and causing unnecessary economic losses.
                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:1200}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The selection of parameters lacks scientific basis
                  </div>
                  <div class="title2">
                    The lack of scientific basis for selecting drilling parameters makes it difficult to flexibly respond to changes in formation pressure, resulting in frequent adjustments to parameters during the drilling process, which affects drilling efficiency and may even trigger safety accidents and delay the schedule.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
                <div class="screenmake1">
                  <div v-sliden-in="{duration:800}" class="heighLight">Function Introduction</div>
                  <div v-sliden-in="{duration:800}" class="heighLight1" style="font-weight: bolder">By monitoring key parameters during drilling in real time, the system can quickly
                    calculate the formation's DC index (drill ability index of the formation) and identify
                    abnormal fluctuations or over threshold situations through machine learning models.
                    Timely pressure warning is helpful for early detection of abnormal pressure, so as to achieve early detection and early disposal and help realize well control safety.
                  </div>


                </div>
        <div ref="freeplay" class="screenmake0" style="background-color: #ebf1fd">
          <div v-sliden-in="{duration:800}" class="heighLight">Function Experience</div>
          <div v-sliden-in="{duration:800}" class="heighLight1">Provide drilling data, calculate DC index, accurately predict formation pressure, and timely
            alert anomalies to improve the safety and efficiency of drilling operations.You can <span style="font-weight: bold;color: red;">switch functions below, free experience</span>!  <br>If you have cooperation intention and product consultation, please <span style="color: #FF6400;cursor: pointer" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>. </div>
          <div class="grid">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="Parameter Display"  name="first">
                <div class="card">
                  <div class="back">
                    <div class="titleDesign">
                      <div style="display: flex">
                        <div class="Title1">
                          1. Data source：
                        </div>
                        <div class="Title2">
                          Logging data;&nbsp;
                          Seismic data;&nbsp;
                          Geological parameters;&nbsp;
                          Fracturing real-time data acquisition&nbsp;
                        </div>
                      </div>
                      <div>
                        <div class="Title1">
                          2.  Operation process：<span class="Title2" >Click on 【Simulated Data Sample】 to download the formation pressure data template file (you can also modify the data in the table). Click the 【Upload】 button, select to upload the engineering file, and after the calculation is completed, output the DC trend line and DC index curve graph.
                 </span>
                        </div>
                        <div class="Title2" style="display: flex">
                        </div>
                      </div>
                      <div style="display: flex">
                        <div class="Title1">
                          *&nbsp;Attention：
                        </div>
                        <div class="Title2">
                          The parameter range should be set according to the
                          threshold specified by the project, and the input parameters should meet the requirements
                          of the model, and continue to update according to user requirements.
                        </div>
                      </div>
                    </div>
                    <div ref="iframeContainer" class="iframeStyle">
                      <iframe :src="iframeUrl1" frameborder="0" allowfullscreen ></iframe> </div>
                  </div>


                </div>
              </el-tab-pane>
              <el-tab-pane label="Abnormal Data" name="second">
                <div class="card">
                  <div class="back">
                    <div class="titleDesign">
                      <div style="display: flex">
                        <div class="Title1">
                          1. Data source：
                        </div>
                        <div class="Title2">
                          Logging data;&nbsp;
                          Seismic data;&nbsp;
                          Engineering parameters;&nbsp;
                          Fracturing real-time data acquisition&nbsp;
                        </div>
                      </div>
                      <div>
                        <div class="Title1">
                          2.  Operation process：<span class="Title2" >Click on 【Simulated Data Sample】 to download the formation pressure data template file (you can also modify the data in the table). Click the 【Upload】 button, select to upload the project file, and after the calculation is completed, analyze and display the abnormal data separately
                 </span>
                        </div>
                        <div class="Title2" style="display: flex">
                        </div>
                      </div>
                      <div style="display: flex">
                        <div class="Title1">
                          *&nbsp;Attention：
                        </div>
                        <div class="Title2">
                          The parameter range should be set according to the
                          threshold specified by the project, and the input parameters should meet the requirements
                          of the model, and continue to update according to user requirements.
                        </div>
                      </div>
                    </div>
                    <div ref="iframeContainer" class="iframeStyle">
                      <iframe :src="iframeUrl2" frameborder="0" allowfullscreen></iframe> </div>
                  </div>


                </div>
              </el-tab-pane>
            </el-tabs>


          </div>

        </div>

        <bottomTip></bottomTip>
      </div>
      <partnerTip
          :dialogVisible="dialogconsult"
          @hideDialog="hide"
      ></partnerTip>
      <consultTip
          :dialogVisible="dialogVisible"
          :thisTitle="title"
          @hideDialog="hideDialog"
      ></consultTip>
    </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div class="screen1">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Formation Pressure<br>Anomaly Alarm</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 23px;color:#2c3e50;text-align: left">
              <li>Monitor drilling parameters in real-time, calculate the DC index from drilling data to accurately predict formation pressure, and provide timely warnings for drilling anomalies</li>
            </div>
          </div>

          <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')" style="font-weight: bolder">
            Product Inquiry
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>


        <div class="screenmake21" style="padding-top: 15px;margin-top: unset">
          <div class="heighLight" style="height: fit-content"> Business Pain Points and Challenges</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">naccurate or Untimely Prediction
                  </div>
                  <div class="title2">
                    Formation pressure is crucial in drilling engineering design, but if abnormal high or low pressure is not predicted in a timely manner, it can easily lead to well control hazards or lost circulation, jeopardizing drilling safety, increasing operational risks, and causing unnecessary economic losses.
                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">The selection of parameters lacks scientific basis
                  </div>
                  <div class="title2">
                    The lack of scientific basis for selecting drilling parameters makes it difficult to flexibly respond to changes in formation pressure, resulting in frequent adjustments to parameters during the drilling process, which affects drilling efficiency and may even trigger
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>Function Introduction</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="imgDesign"> </div>
                  <div>
                    <div class="Title2">
                      By monitoring key parameters during drilling in real time, the system can quickly
                      calculate the formation's DC index (drill ability index of the formation) and identify
                      abnormal fluctuations or over threshold situations through machine learning models.
                      Timely pressure warning is helpful for early detection of abnormal pressure, so as to achieve early detection and early disposal and help realize well control safety.

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="screenmake2" style="background: #D2E3FF !important;">
          <div class="heighLight">   <span>Function Experience</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign" style="margin-top: 0">
                  <div class="Title2" style="margin-left: 0">
                    The function of Abnormal formation pressure condition alarm covers <span style="color:#FF6400;">parameter display, abnormal data detection</span>.You can go to the computer to experience the free functions!<br>Experience link (PC version): <br><span style="border-bottom: #333333 1px solid">https://www.oilgasgpts.com/alarm</span><br>
                    If you have cooperation intention and product consultation, please <span style="color:red" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>
<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
// import consultTip from "@/components/consultTip.vue";
// import PartnerTip from "@/components/partnerTip.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  // components: {PartnerTip, consultTip, headtip,bottomTip},
  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
      changeVisable:1,
      title:"",
      pageTitle:'Abnormal Formation Pressure Condition Alarm Platform',
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      model:false,
      activeName: 'first',
      userId:localStorage.getItem("userId"),
      // iframeUrl1:"https://ab.oilgasgpts.com/frackEn/zhngchffzhshj",
      iframeUrl1:"https://ab.oilgasgpts.com/frackEn/params-show",
      iframeUrl2:"https://ab.oilgasgpts.com/frackEn/params-error",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

   methods: {
     freeExperience(){
       // 获取 ref 为 'freeplay' 的元素
       const freeplaySection = this.$refs.freeplay;
       // 使用 scrollIntoView 定位到该元素
       freeplaySection.scrollIntoView({ behavior: 'smooth' });
     },
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
 const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
     handleClick(tab, event) {
       console.log(tab, event);
     },

    clickConsult(item){
      // console.log("联系联系")
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     changeheadVisable(){
       if(this.changeVisable===1){
         this.changeVisable=0
       }
       else{
         this.changeVisable=1
       }
       console.log("父组件", this.changeVisable)
     },
     handleScroll() {
         this.scrollHeight = this.$refs.total.scrollTop;
       if (this.scrollHeight < 100) {
         this.scrollTrue = true
       } else {
         this.scrollTrue = false
       }
       if (this.scrollHeight < 50) {
         this.scrollback = false
       } else {
         this.scrollback = true
       }
       console.log("gaodu", this.scrollHeight < 100)
     },
   },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {
    localStorage.setItem("home",0)
    document.title=this.pageTitle
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Abnormal Formation Pressure Condition Alarm Platform',
        pageDescription: 'The Oil & Gas GPT Cloud Platform provides process-oriented GPTs, which not only contribute to enhancing development effectiveness and recovery rates but also minimize risk and losses during the development process,',
        pageKeywords: 'Process GPTs, process GPTs, intelligent geosteering',
      };
      shareUrl(shareData);
    }, 1000);

  },
}

</script>

<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: white;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 5.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/gpt21.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .layer2 {
        margin-top: 10vh;
        margin-left: 2vw;

        .title1 {
          width: fit-content;
          //height: 7.77626699629172vh;
          font-size: 2.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 2.63733003708282vw;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.8vw;
        }

        .title4 {
          margin-top: 3vh;
          width: 40vw;
          font-size: 1vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          line-height: 7vh;
        }

        .titlle3 {
          position: absolute;
          bottom: 7.78739184177998vh;
          margin-top: 4.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: bolder;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 0px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          //border: #2168DB 1px solid;
          background: orange;
          //background: rgba(255, 255, 255, 0.5);
        }
      }
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1.4vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0 {
      padding: 6.8430160692vh 9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }

      ::v-deep .el-tabs__nav {
        text-align: center;
        float: none;
      }

      ::v-deep .el-tabs__item {
        width: 50%;
        font-size: 1.2vw !important;
        padding: 2vh 5vh;
        height: fit-content;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #316fff;
      }

      ::v-deep .el-tabs__header {

        margin: 0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #316fff;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 85vh;;
            overflow: hidden;

            .iframeStyle {
              background-color: #ececec;
              margin: -9vh 0 0 -9.8vw;
              width: 100vw;
              height: 107vh;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }

            .iframeStyle iframe {
              background-color: #ececec;
              width: 100vw;
              min-height: 107vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }

            .imgDesign {

              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 2vh 3vw;
              width: 100%;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;

              .Title1 {

                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: bold;
                line-height: 1.2vw;
              }

              .Title2 {
                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }
    .screenmake1{
      padding:6.8430160692vh  8.9763142692085vw 6.8430160692vh;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }

      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr)  minmax(0,1fr)  minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 5px;
            position: relative;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:50vh ;
            overflow:hidden;
            padding: 0 0 3vh 0;
            .imgDesign{
              height:8vh;
              width: 8vh;
              display: flex;
              margin:2vh auto;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              align-items: center; /* 垂直居中 */
              justify-content: center; /* 水平居中 */
              .Title1{
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1.4vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              .Title2{
                margin:auto 1.5vw;
                color:#111111;
                //text-align: justify;
                font-size: 1.2vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3vh;
              }
              li{
                margin-top: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 600px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color: #fff;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: white;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;
      .title1 {
        padding:0 15px;
        margin:25px auto;
        width: fit-content;
        text-align: center;
        height: fit-content ;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height:26PX;
      }

      .title2 {
        margin: 25px auto 0 auto;
        width: 90%;
        text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
      .title3 {
        margin: 18px auto;
        width: 80%;
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {

      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }


          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }

          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake1{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            //position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 150px;
            opacity: 1;
            background-image: url("../../assets/alarm1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 220px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 150px;
              margin-top: -5px;

              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 10px 40px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake21{
    width: 100vw;
    height: fit-content;
    margin-top: -15px;
    padding:0  4.6875vw 20px 4.6875vw;
    background: white;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 10px;
      grid-template-columns: minmax(0,1fr) ;
      column-gap: 5px;
      .card{
        cursor: pointer;
        width: 100%;
        height:fit-content ;
        display: flex;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh ;
        opacity: 1;
        .title1{
          margin: 5px auto;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
        }
        .title2{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .title3{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .titleDesign{
          width: 90%;
          margin: 0 0 0 15px;
        }
        .back{
          background: white;
          border-radius: 9px;
          column-gap: 5px;
          position: relative;
          padding: 0 10px 0 10px;
          height:fit-content;
          display: flex;
          overflow:hidden;
          .imgDesign{
            width: 45px;
            height:45px;
            margin:0 auto 15px auto;
            opacity: 1;
          }


        }

      }

      ::v-deep .el-carousel__container{
        height: 65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/alarm1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 163px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              margin-top: -146px;
              height: 150px;
              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin:13px 15px 10px 13px;
              text-align: left;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;


    padding: 20px 15px;
    background: black;

    .heighLight {
      text-align: center;
      height: 21px;

      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;

        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
