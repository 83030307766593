<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
          <div class="screen1">
             <kefu @toptop="topTop"></kefu>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1">Geological Analysis GPTs</div>
              <div v-sliden-instep="{duration:1200}"  class="title2">Leveraging AI models for geological interpretation in oil&gas development, assisting field personnel in real-time understanding of reservoir conditions and operations, providing decision support for development
              </div>
              <div v-sliden-instep="{duration:1600}"  class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications.
                <span style="color: #eb7c31"><br>①&nbsp;Explanatory services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</span></div>

              <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake2">
<!--            <transition name="run" appear>-->
            <div v-sliden-instep="{duration:2000}" class="heighLight">
              Geological Analysis GPTs
            </div>
<!--            </transition>-->

            <div class="grid">
              <!--            <div class="card" @click="detailPC1()">-->
              <a href="/fiber" target="_blank">
              <div v-sliden-in="{duration:800}"  key="anxun1"  class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt73.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1"> Fiber Optic Wellbore Leakage Point Real-time Prediction Platform</div>
                    <div class="Title2">
                      <li>Real-time analysis of geological stratification using models</li>
                        <li>Automatic generation of early warning messages to assist engineers in adjusting drilling parameters promptly</li>
                    </div>
                    <div class="backconsult">
                     Free Trial
                    </div>
                  </div>
                </div>
              </div>
              </a>
              <div v-sliden-in="{duration:1200}"  key="anxun2" class="card" >
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt13.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1" style="display: flex;justify-content: center;align-items: center"> Reservoir / Lithology Identification</div>
                    <div class="Title2">
                      Oil & Gas reservoir is a rocky area where Oil & Gas collect underground in Oil & Gas exploration engineering. Reservoir characteristics include lithology, physical properties, and hydrocarbon content, which is the main direction of reservoir prediction. Reservoir lithology is the main feature that describes the mineral composition of the reservoir, reflecting the storage performance and reservoir characteristics of the formation, and the commonly used parameters include the physical structure of the reservoir rock, the distribution range, the tectonic morphology of the top interface of the reservoir, and the thickness of the reservoir. Comprehensive reservoir characteristics can realize the prediction of Oil & Gas rich area, i.e. sweet spot, and indicate the location and exploitation plan for Oil & Gas drilling.</div>
                    <div class="backconsult">
                      developing...
                    </div>
                  </div>
                </div>


              </div>
              <div  v-sliden-in="{duration:1600}"  key="anxun3" class="card" >
                <!--            <div class="card" @click="detailPC3()">-->
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt63.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1" style="display: flex;justify-content: center;align-items: center">  Equipment Intelligent Monitoring</div>
                    <div class="Title2">Through the online monitoring system, the operating status data of the well dressing machine can be monitored in real time. This real-time not only provides technical support for the field operators, but also enables the oilfield managers at all levels to understand the status of the production site in a timely manner, so as to provide a basis for decision-making.</div>
                    <!--                  <div class="backconsult">-->
                    <!--                     Free Trial-->
                    <!--                  </div>-->
                    <!--                  <div class="backconsultArrow">-->
                    <!--                    <i style="font-size: 12px;margin-left: 35px;" class="el-icon-right"></i>-->
                    <!--                  </div>-->
                    <div class="backconsult">
                      developing...
                    </div>
                  </div>
                </div>


              </div>

              <div v-sliden-in="{duration:800}" key="anxun4" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt25.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1"> Geological Sweet Spot/Engineering Sweet Spot Identification</div>
                    <!--                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">-->
                    <!--                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">-->
                    <!--                      In the process of Oil & Gas exploration and development, large Oil & Gas areas and large segments of Oil & Gas layers will be revealed, but often some of them have better development benefits under the current economic and technological conditions, which are called "sweet spots". Taking the measured geological and engineering parameters as inputs and utilizing the neural network simulation technology, the correlation between geological parameters and elasticity parameters is intelligently analyzed through big data, and the corresponding sweet spot evaluation parameters are deciphered.-->
                    <!--                    </div>-->
                    <!--                  </el-tooltip>-->
                    <div class="Title2">
                      In the process of Oil & Gas exploration and development, large Oil & Gas areas and large segments of Oil & Gas layers will be revealed, but often some of them have better development benefits under the current economic and technological conditions, which are called "sweet spots". Taking the measured geological and engineering parameters as inputs and utilizing the neural network simulation technology, the correlation between geological parameters and elasticity parameters is intelligently analyzed through big data, and the corresponding sweet spot evaluation parameters are deciphered.

                    </div>
                    <div class="backconsult">
                      developing...
                    </div>


                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1500}"  key="anxun5" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt35.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">
                      Dynamic Evaluation Of Artificial Fracture Reforming Volume</div>
                    <!--                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">-->
                    <!--                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">-->
                    <!--                      Comprehensive comparative evaluation is carried out based on the actual natural fracture response, ground stress and brittleness of each section of each well on each platform; the degree of artificial fracture complexity described qualitatively and quantitatively; and the degree of compression tampering qualitatively and quantitatively.-->
                    <!--                    </div>-->

                    <!--                  </el-tooltip>-->
                    <div class="Title2">
                      Comprehensive comparative evaluation is carried out based on the actual natural fracture response, ground stress and brittleness of each section of each well on each platform; the degree of artificial fracture complexity described qualitatively and quantitatively; and the degree of compression tampering qualitatively and quantitatively.
                    </div>
                    <div class="backconsult">
                      developing...
                    </div>


                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:2000}" key="anxun6" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt74.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">  Fiber-optic Liquid-producing Profile And Gas-producing Profile Monitoring</div>
                    <!--                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">-->
                    <!--                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">-->
                    <!--                      Fiber optic production profile testing technology realizes fiber optic distributed monitoring by pumping armored fiber optic cables into the continuous tubing in advance, and then transmitting the continuous tubing to the destination test layer through the continuous tubing truck set during operation. The fiber optic production profile monitoring technology uses the fiber optic itself as the sensor, without additional downhole logging instruments, and detects the temperature and acoustic wave data along the downhole fiber optic by measuring the backscattered light, and then analyzes the fluids produced in the well through real-time iteration, thus realizing the monitoring of the production profile of various clusters in the Oil & Gas wells.-->
                    <!--                    </div>-->

                    <!--                  </el-tooltip>-->
                    <div class="Title2">
                      Fiber optic production profile testing technology realizes fiber optic distributed monitoring by pumping armored fiber optic cables into the continuous tubing in advance, and then transmitting the continuous tubing to the destination test layer through the continuous tubing truck set during operation. The fiber optic production profile monitoring technology uses the fiber optic itself as the sensor, without additional downhole logging instruments, and detects the temperature and acoustic wave data along the downhole fiber optic by measuring the backscattered light, and then analyzes the fluids produced in the well through real-time iteration, thus realizing the monitoring of the production profile of various clusters in the Oil & Gas wells.
                    </div>

                    <div class="backconsult">
                      developing...
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}"  key="anxun7" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/gpt75.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">Dynamic Monitoring Of Fracturing Sand And Fluid Intake</div>
                    <!--                  <el-tooltip  placement="bottom" effect="light" popper-class="custom-tooltip" :class="'custom-tooltip'">-->
                    <!--                    <div style="width: 50vw ;font-size: 0.8243212016vw; line-height: 3.194066749vh;" slot="content">-->
                    <!--                      Fiber-optic fracturing monitoring technology mainly makes use of distributed optical fibers in the same well or neighboring wells to carry out distributed acoustic vibration (DAS) and distributed strain sensing (DSS) monitoring. Through DAS monitoring in the same well, it can visually display the situation of fluid and sand feeding into the fracture clusters, so as to guide fracturing temporary plugging and shift to optimize stratum modification. Through real-time monitoring of low-frequency strain signals, it depicts the development process and state of fractured cracks, and guides fracturing in real-time; it also extracts microseismic signal events to evaluate the fracturing effect and optimize the fracturing plan.-->
                    <!--                    </div>-->

                    <!--                  </el-tooltip>-->
                    <div class="Title2">
                      Fiber-optic fracturing monitoring technology mainly makes use of distributed optical fibers in the same well or neighboring wells to carry out distributed acoustic vibration (DAS) and distributed strain sensing (DSS) monitoring. Through DAS monitoring in the same well, it can visually display the situation of fluid and sand feeding into the fracture clusters, so as to guide fracturing temporary plugging and shift to optimize stratum modification. Through real-time monitoring of low-frequency strain signals, it depicts the development process and state of fractured cracks, and guides fracturing in real-time; it also extracts microseismic signal events to evaluate the fracturing effect and optimize the fracturing plan.
                    </div>
                    <div class="backconsult">
                      developing...
                    </div>

                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div  v-left-in="{duration:800}"  class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div  v-sliden-in="{duration:800}"  class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>

        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
          <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <headtip @showDialog="showPartner"></headtip>

          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Geological Analysis GPTs</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 28px;color:#2c3e50">Leveraging AI models for geological interpretation in oil&gas development, assisting field personnel in real-time understanding of reservoir conditions and operations, providing decision support for development</div>


          </div>
          <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')" style="font-weight: bolder">
            Product Inquiry
          </div>
          <div v-zoom-in="{duration:800}" class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. <br>①&nbsp;Explanatory services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>

          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的.</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">   <span>Explanatory GPTs Services</span></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back" @click="detail2">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">Fiber Optic Wellbore Leak Real-time Prediction</div>
                  <div class="Title2">
                    Real-time analysis of geological stratification using models.Automatic generation of early warning messages to assist engineers in adjusting drilling parameters promptly.
                  </div>
                    <div class="backconsult" @click="detail2" style="font-weight: bolder;color: #eb7c31">
                    Free Trial
                  </div>
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size: 12px;margin-left: 42px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back"  >
<!--              <div class="back"  @click="detail1">-->
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 220px">Reservoir / Lithology Identification</div>
                  <div class="Title2">
                    Oil & Gas reservoir is a rocky area where Oil & Gas collect underground in Oil & Gas exploration engineering. Reservoir characteristics include lithology, physical properties, and hydrocarbon content, which is the main direction of reservoir prediction. Reservoir lithology is the main feature that describes the mineral composition of the reservoir, reflecting the storage performance and reservoir characteristics of the formation, and the commonly used parameters include the physical structure of the reservoir rock, the distribution range, the tectonic morphology of the top interface of the reservoir, and the thickness of the reservoir. Comprehensive reservoir characteristics can realize the prediction of Oil & Gas rich area, i.e. sweet spot, and indicate the location and exploitation plan for Oil & Gas drilling.
                  </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
<!--                  <div class="backconsult" @click="detail1">-->
<!--                     Free Trial-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size: 12px;margin-left:35px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back" >
<!--              <div class="back" @click="detail3">-->
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1" style="margin-top: 220px">Equipment Intelligent Monitoring</div>
                  <div class="Title2">
                    Through the online monitoring system, the operating status data of the well dressing machine can be monitored in real time. This real-time not only provides technical support for the field operators, but also enables the oilfield managers at all levels to understand the status of the production site in a timely manner, so as to provide a basis for decision-making.
                  </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
<!--                  <div class="backconsult" @click="detail3">-->
<!--                     Free Trial-->
<!--                  </div>-->
<!--                  <div class="backconsultArrow">-->
<!--                    <i style="font-size: 12px;margin-left: 35px;" class="el-icon-right"></i>-->
<!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}"  class="card2">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Geological Sweet Spot/Engineering Sweet Spot Identification</div>
                  <div class="Title2">In the process of Oil & Gas exploration and development, large Oil & Gas areas and large segments of Oil & Gas layers will be revealed, but often some of them have better development benefits under the current economic and technological conditions, which are called "sweet spots". Taking the measured geological and engineering parameters as inputs and utilizing the neural network simulation technology, the correlation between geological parameters and elasticity parameters is intelligently analyzed through big data, and the corresponding sweet spot evaluation parameters are deciphered.
                </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
                </div>

              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Dynamic Evaluation Of Artificial Fracture Reforming Volume</div>
                  <div class="Title2">Comprehensive comparative evaluation is carried out based on the actual natural fracture response, ground stress and brittleness of each section of each well on each platform; the degree of artificial fracture complexity described qualitatively and quantitatively; and the degree of compression tampering qualitatively and quantitatively.
                </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
                </div>

              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign1"></div>
                <div class="titleDesign">
                  <div class="Title1">Fiber-optic Liquid-producing Profile And Gas-producing Profile Monitoring</div>
                  <div class="Title2">Fiber optic production profile testing technology realizes fiber optic distributed monitoring by pumping armored fiber optic cables into the continuous tubing in advance, and then transmitting the continuous tubing to the destination test layer through the continuous tubing truck set during operation. The fiber optic production profile monitoring technology uses the fiber optic itself as the sensor, without additional downhole logging instruments, and detects the temperature and acoustic wave data along the downhole fiber optic by measuring the backscattered light, and then analyzes the fluids produced in the well through real-time iteration, thus realizing the monitoring of the production profile of various clusters in the Oil & Gas wells.
                </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
                </div>

              </div>

            </div>
            <div v-sliden-in="{duration:800}" class="card1">
              <div class="back">
                <div class="imgDesign2"></div>
                <div class="titleDesign">
                  <div class="Title1">Dynamic Monitoring Of Fracturing Sand And Fluid Intake</div>
                  <div class="Title2">Fiber-optic fracturing monitoring technology mainly makes use of distributed optical fibers in the same well or neighboring wells to carry out distributed acoustic vibration (DAS) and distributed strain sensing (DSS) monitoring. Through DAS monitoring in the same well, it can visually display the situation of fluid and sand feeding into the fracture clusters, so as to guide fracturing temporary plugging and shift to optimize stratum modification. Through real-time monitoring of low-frequency strain signals, it depicts the development process and state of fractured cracks, and guides fracturing in real-time; it also extracts microseismic signal events to evaluate the fracturing effect and optimize the fracturing plan.
                </div>
                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">
                    developing...
                  </div>
                </div>

              </div>


            </div>
          </div>

        </div>
        <div class="screenmake3">
          <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
          <div class="grid">
            <el-carousel indicator-position="outside" arrow="always" interval="5000">
              <el-carousel-item v-for="item in success" :key="item">
                <div class="card">
                  <div class="back">
                    <div v-zoom-in="{duration:800}" class="imgDesign">
                      <img :src="item.img"/>
                    </div>
                    <div v-zoom-in="{duration:800}" class="titleDesign">
                      <div class="title1">{{ item.title1 }}</div>
                      <div class="title2" v-html="item.title2"></div>
                    </div>
                  </div>


                </div>


              </el-carousel-item>
            </el-carousel>
          </div>


        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import kefu from "@/views/kefu.vue";
import recognition from "@/views/third/recognition.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vLeftIn} from '@/utils/vLeftIn.js'
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {

  name: "",
  computed: {
    recognition() {
      return recognition
    }
  },
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefu,kefuMobile},
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    if(this.model===true){

      this.scrollPosition = this.$refs.totalModel.scrollTop;
    }
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {
        if(vm.model===true) {
          vm.$refs.totalModel.scrollTop = vm.scrollPosition;
        }
      });
    });
  },
  data() {

  return {changeVisable:1,
      title:'',
      pageTitle: 'Interpretation GPTs_Explanation large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
      pageDescription: 'The Oil & Gas GPT Cloud Platform offers Explanatory GPTs, which are based on the interpretation of Oil & Gas development targets. These GPTs not only assist engineering personnel in real-time understanding of the geological conditions of Oil & Gas reservoirs and the status of engineering operations, but also provide strong support for decision-making and adjustments during the development process.',
      pageKeywords: 'Interpretation GPTs, interpretation-type GPTs, reservoir identification, lithology identification, equipment intelligent monitoring, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',
    scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[
        {
          img:require("@/assets/gpt76.png"),
          title1:"XX Oilfield Fiber Optic Intelligent Oil & Gas Production Profile Monitoring Project",
          title2:"Anton provides oil Oil & Gas production and profile monitoring project services for clients in Southwest Oil & Gas fields. Intelligent analysis and interpretation is used to obtain production profiles, optimize the production system and adjust the well network deployment, and improve the recovery rate; through the analysis of the fracturing fluid return and the evaluation of the fracturing effect, the fracturing plan can be optimized in the reverse direction.",
        },
        {
          img:require("@/assets/gpt66.png"),
          title1:"Online Monitoring And Predictive Maintenance Of Drilling And Repairing Machines",
          title2:"We have built a predictive maintenance platform for drilling and repairing machine equipment to realize online condition monitoring (intelligent warning, intelligent diagnosis, intelligent assessment, etc.).<br><span style=\"margin-top: 5vh\">Key Promotion Direction：</span>  " +
              "<li>Enhance the ability to share heterogeneous information data from multiple sources, and realize unified and centralized management of data.</li>\n"
                 },
        {
          img:require("@/assets/gpt66.png"),
          title1:"Online Monitoring And Predictive Maintenance Of Drilling And Repairing Machines",
          title2:"We have built a predictive maintenance platform for drilling and repairing machine equipment to realize online condition monitoring (intelligent warning, intelligent diagnosis, intelligent assessment, etc.).<br><span style=\"margin-top: 5vh\">Key Promotion Direction：</span> " +
              "   <li style=\"margin-top: 0.5vh\">Enhance the ability to empower the application scenarios of artificial intelligence technology, establish algorithms and logic control models based on the big data platform and different application scenarios of various subsystems.</li>\n" +
              "   <li style=\"margin-top: 0.5vh\">Enhance the decision-making ability of risk control of drilling and repairing machine operation.</li>",
        },
      ]
    }
  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
          const element = this.$refs.total;
          const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          this.$nextTick(() => {
            this.$refs.total.scrollTop = rect
          });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail1(){
      this.$router.push("/recognition1")
    },
    detail2(){
      this.$router.push("/fiber")
    },
    detail3(){
      this.$router.push("/equipment1")
    },
    detailPC1(){
      window.open("/recognition", "_blank");
    },
    detailPC2(){
      window.open("/fiber", "_blank");
    },
    detailPC3(){
      window.open("/equipment", "_blank");
    },


    showPartner() {
      this.dialogconsult = true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      if(localStorage.getItem("home")==="0"){
        const element = this.$refs.bottom;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll)
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    // if (localStorage.getItem("skip") === '3') {
    //   this.$nextTick(() => {
    //     this.skipp3();
    //   });
    // }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Interpretation GPTs_Explanation large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
        pageDescription: 'The Oil & Gas GPT Cloud Platform offers Explanatory GPTs, which are based on the interpretation of Oil & Gas development targets. These GPTs not only assist engineering personnel in real-time understanding of the geological conditions of Oil & Gas reservoirs and the status of engineering operations, but also provide strong support for decision-making and adjustments during the development process.',
        pageKeywords: 'Interpretation GPTs, interpretation-type GPTs, reservoir identification, lithology identification, equipment intelligent monitoring, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>

<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  //width: 100vw;
  //height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 6.86077411900635vw;
    background-image: url("../../assets/gpt6.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      margin-top: 10vh;
      margin-left:2vw;

      .title1 {
        width: fit-content;
        height: fit-content;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: bolder;
        color: #282d7b;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin-top: 2.82076637824475vh;
        width: 36vw;
        height: fit-content;
        //text-align: justify;
        font-size: 1.3vw;
        font-family: 'Times New Roman';
        font-weight: bolder;
        color: #282d7b;
        line-height: 3.5vh;
      }
      .title4 {
        margin-top: 4.82076637824475vh;
        width: 36vw;
        //text-align: justify;
        font-size: 1.0vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        line-height:3.5vh;
      }
      .titlle3{
        position: absolute;
        bottom: 10.78739184177998vh;
        margin-top: 2.82076637824475vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;

        line-height: 2.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;


      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake2{
    padding: 7.78739184177998vh 11.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;
    .run-enter-active {
      animation: fadeInDown 1s ease-in-out both;
    }
    .rundown1-enter-active {
      animation: slideInUp 1s ease-in-out both;
    }
    .rundown2-enter-active {
      animation: slideInUp 1.2s ease-in-out both;
    }
    .rundown3-enter-active {
      animation: slideInUp 1.4s ease-in-out both;
    }
    .heighLight{
      display: flex;
      //justify-content: ;
      text-align: left;
      height:8.23733003708282vh;
      font-size: 2.3vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: #111111;
      line-height: 4.23733003708282vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:15px;
      .card {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        overflow: hidden; /* 防止子元素的绝对定位导致的问题 */
        .back {
          border-radius: 9px;
          position: relative;
          border: 2px solid white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          min-height: 70vh;
          overflow: hidden;
          transition: transform 0.8s ease-out, height 0.8s ease-out;
          background: #015377;
        }
        .imgDesign {
          overflow: hidden;
          width: 100%;
          height: 40vh;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;
          transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
          visibility: visible;
        }
        .titleDesign {
          position: relative;
          //bottom: 2vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          max-height: 30vh;
          height: 30vh;
          transition: max-height 0.5s ease-out,min-height 0.5s ease-out;
          .Title1 {
            position: absolute;
            margin: 1vh 1.8597920277vw 0 1.8597920277vw;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            //height: 20vh;
            font-size: 1.6vw;
            font-family: 'Times New Roman';
            font-weight: 700;
            transition: transform 0.8s ease-out;
            color: white;
            line-height: 4vh;
          }
          .Title2 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            position: absolute;
            bottom: 0;
            color: white;
            opacity: 1;
            min-height: 14vh;
            max-height: 15vh;
            overflow: hidden;
            transition: opacity 0.5s ease-out;
            margin: 0 1.8597920277vw 5vh 1.8597920277vw;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            line-height: 3.5vh;
            flex-grow: 1;

          }
          .backconsult {
            position: absolute;
            bottom: 1vh;
            text-align: left;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: bolder;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            //right: 1.8597920277vw;
            left: 50%;
            transform: translateX(-50%);
          }
          .backconsultArrow {
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 8vw;
          }
        }
      }


      .card:hover {
        .imgDesign {
          //opacity: 0;
          //visibility: hidden;
          transform: scale(1.05);
        }
        .titleDesign{
          //max-height: 100vh;
          //min-height: 45vh;
          //top:2vh;
          transform: scale(1.01);
          .Title1 {
            //display: block;
            //height: fit-content;
          }
          .Title2 {
            //opacity: 1;
            //max-height: 100vh;
            //min-height: 45vh;
          }
          .backconsult{
            //display: none;
          }
        }
      }

    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 5.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    //background-color: #ebf1fd;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 2.3vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      //color: #2168DB;
      line-height: 2.71940667490729vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        width: 86%;
        margin:0 5.09763142692085vw;
        height:75vh;
        padding:0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 5px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:70vh;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: fit-content;
            height:100%;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.0248454883vw;
            color: white;
            width: 30vw;
            height: fit-content;
            margin:auto 0 auto 0;
            ////text-align: justify;
            float: right;
            .title1{
              font-size: 1.4vw;
              line-height: 4vh;
            }
            .title2{
              margin-top: 10px;
              line-height: 4vh;
              font-size: 1.2vw;
            }

          }

        }
      }

      ::v-deep .el-carousel__container{
        height:75vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 550px;
      }
      ::v-deep .el-carousel__arrow {
        outline: 10vh;
        padding: 0;
        margin: 0  ;
        //cursor: pointer;

        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: rgba(249, 250, 252, 0.2);
        border: rgba(255, 255, 255, 0.5) 1px solid;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-weight:800;
        font-size: 25px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 600px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel71.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-115px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:15px auto 0 auto;
        width: fit-content;
        //text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width:85%;
        //text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }
  .screenmake2{
    padding:135px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/gpt13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 180px;
            opacity: 1;
            background-image: url("../../assets/gpt73.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/gpt63.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{

              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 8px 12px 40px 12px;
              ////text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }

      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 240px;
            opacity: 1;
            background-image: url("../../assets/gpt14.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{

              margin-top: 250px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              ////text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt35.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign1{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt74.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .imgDesign2{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/gpt75.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{

              margin-top: 195px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 700;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{

              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              ////text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background:#2C3E50;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      ////text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        color: black;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 670px ;
          overflow:hidden;
          background-color: #19202e;
          .imgDesign{
            width: 100%;
            height:234px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 14px;
              line-height: 20px;
            }
            .title2{
              margin-top: 15px;
              line-height: 22px;
              font-size: 12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:670px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -225px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
