<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <div class="background">
             <kefu @toptop="topTop"></kefu>
            <!--     文字-->
            <div class="layer2">
              <div class="title1" v-sliden-instep="{duration:800}" style="font-weight: bolder;color:#282d7d;line-height: 6vh ">Enterprise Management Knowledge<br>GPT Development Services</div>
              <div class="title2" v-sliden-instep="{duration:1000}" style="font-weight: bolder;margin-top: 7vh;color:#282d7d;line-height: 4vh">
                Construct a large-scale language model AI based on internal management knowledge across various fields of the enterprise, enabling employees to conveniently access the knowledge they need
              </div>
              <div  class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')" style="font-weight:bolder">
                 Product Inquiry
              </div>
            </div>
          </div>
          <div  class="screenmake21" style="padding: 7.7873918418vh 11.0976314269vw 0 ">
            <div v-sliden-in="{duration:800}"  class="heighLight" style="font-size: 2.1vw;font-weight: bolder">Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div  class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Dispersed Knowledge Achievements and Relatively Difficult Integration
                    </div>
                    <div class="title2">
                      Knowledge achievements within the enterprise are scattered and not effectively integrated and uniformly managed, which is not convenient for knowledge sharing and efficient application.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficult Knowledge Inheritance
                    </div>
                    <div class="title2">
                      There is no effective knowledge inheritance mechanism. When there are personnel changes, a large amount of knowledge and experience is lost.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/innovation.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of an Atmosphere for Knowledge Innovation
                    </div>
                    <div class="title2">
                      There is no knowledge application platform. Employees lack the motivation for knowledge innovation, making it difficult to stimulate new business ideas.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-weight: bolder"> Service Offerings</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                <div class="imgDesign">
<!--                  <img style="border-radius: 9px" loading="lazy" src="../../assets/knowledge.png">-->
                  <video id="myVideo" ref="myVideo" width="100%" height="100%" style="border-radius: 9px" controls  muted playsinline>
                    <source src="@/video/gptKnowledge.mp4" type="video/mp4">
                  </video>
                </div>
                <div class="titleDesign">
                  <div class="title2">
                    <li style="margin-bottom: 1vh">Management knowledge needs and scope</li>
                    <li style="margin-bottom: 1vh">Large model selection and direction</li>
                    <li style="margin-bottom: 1vh">Computing power estimation</li>
                    <div style="display: flex;margin-bottom: 1vh">
                      <li></li>
                      Services for knowledge document collection, classification, and processing</div>
                    <li style="margin-bottom: 1vh">Model training, fine-tuning, and evaluation</li>
                    <li style="margin-bottom: 1vh">Application development, deployment, and testing</li>
                    <li style="margin-bottom: 1vh">Launch, promotion, and training</li>
                  </div>
                </div>
                </div>
              </div>
            </div>

          </div>

          <div class="screenmake1" style="font-weight: bolder">
            <div class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div  class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <img style="border-radius: 9px" loading="lazy" :src="item.img"/>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel" ref="totalModel">
        <div class="makeHole1">
          <div class="background">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <headtip @showDialog="showPartner"></headtip>
            <!--     文字-->
            <div class="layer2">
              <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Enterprise Management Knowledge GPT Development Services</div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 23px;color:#2c3e50;text-align: left">
                Construct a large-scale language model AI based on internal management knowledge across various fields of the enterprise, enabling employees to conveniently access the knowledge they need</div>
            </div>
           <div class="title3"   @click="clickConsult('Oil & Gas Generic AI')">
               Product Inquiry
            </div>
          </div>
          <div class="screenmake21">
            <div v-sliden-in="{duration:800}" class="heighLight" >  Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Dispersed Knowledge Achievements and Relatively Difficult Integration
                    </div>
                    <div class="title2">
                      Knowledge achievements within the enterprise are scattered and not effectively integrated and uniformly managed, which is not convenient for knowledge sharing and efficient application.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficult Knowledge Inheritance
                    </div>
                    <div class="title2">
                      There is no effective knowledge inheritance mechanism. When there are personnel changes, a large amount of knowledge and experience is lost.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/innovation.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of an Atmosphere for Knowledge Innovation
                    </div>
                    <div class="title2">
                      There is no knowledge application platform. Employees lack the motivation for knowledge innovation, making it difficult to stimulate new business ideas.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight"> Service Offerings</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div v-zoom-in="{duration:800}" class="imgDesign">
                    <video id="myVideo1" ref="myVideo1" width="100%" height="100%" style="border-radius: 9px" controls  muted playsinline>
                      <source src="@/video/gptKnowledge.mp4" type="video/mp4">
                    </video>
                  </div>
                  <div v-sliden-in="{duration:800}" class="titleDesign">
                    <div class="title2">
                      <li>Management knowledge needs and scope</li>
                      <li>Large model selection and direction</li>
                      <li>Computing power estimation</li>
                      <li>Services for knowledge document collection, classification, and processing</li>
                      <li>Model training, fine-tuning, and evaluation</li>
                      <li>Application development, deployment, and testing</li> <li>Launch, promotion, and training
                    </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake1">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-zoom-in="{duration:800}" class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div v-zoom-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },

  data() {

  return {changeVisable:1,
      title:'',
    scrollback:false,
      pageTitle: 'Enterprise internal management knowledge GPT development service _ enterprise management GPT - Oil & Gas GPT',
      pageDescription: 'Oil & Gas GPT Cloud Platform (www.oilgasgpts.com) provides internal management knowledge GPT development services, based on large model GPT capabilities, building Andongtong GPT, embedded in the group\'s domain document knowledge, intelligent Q & A dialogue, employees can quickly and conveniently obtain knowledge.',
      pageKeywords: 'Knowledge GPT, Management GPT, Enterprise Management GPT, Knowledge GPT Development, Oil & Gas, Oil & Gas GPT, Oil & Gas GPT Cloud Platform',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/knowledgenew.png"),
        title1:"Anton Knowledge Communication",
        title2:"Based on the large-model GPT capabilities, Anton GPT is built to embed document knowledge in various fields of the group to realize intelligent question and answer dialogue, so employees can quickly and easily obtain knowledge.",
      },{
        img:require("@/assets/knowledge2.png"),
        title1:"Anton Knowledge Communication",
        title2:"Based on the large-model GPT capabilities, Anton GPT is built to embed document knowledge in various fields of the group to realize intelligent question and answer dialogue, so employees can quickly and easily obtain knowledge.",
      },

      ]
    }

  },

  methods: {

     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // const element = this.$refs.totalModel;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      // this.$nextTick(() => {
      //   this.$refs.totalModel.scrollTop = rect
      // });
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);

    if(this.model===true){
      const videoEl = this.$refs.myVideo1;
      videoEl.play();
      videoEl.addEventListener('ended', () => {
        // 视频播放结束后，等待10秒（10000毫秒）
        setTimeout(() => {
          // 重置视频播放位置到开始，并播放
          videoEl.currentTime = 0;
          videoEl.play();
        }, 10000);
      });
    }else{
      const videoEl = this.$refs.myVideo;
      videoEl.play();
      videoEl.addEventListener('ended', () => {
        // 视频播放结束后，等待10秒（10000毫秒）
        setTimeout(() => {
          // 重置视频播放位置到开始，并播放
          videoEl.currentTime = 0;
          videoEl.play();
        }, 10000);
      });
    }

    const videoEl = this.$refs.myVideo;
    // 组件销毁前移除事件监听器，避免内存泄漏
    videoEl.removeEventListener('ended', () => {
      videoEl.currentTime = 0;
      videoEl.play();
    });


    const videoEl1 = this.$refs.myVideo1;
    // 组件销毁前移除事件监听器，避免内存泄漏
    videoEl1.removeEventListener('ended', () => {
      videoEl1.currentTime = 0;
      videoEl1.play();
    });
  },
  mounted() {


    this.$refs.total.addEventListener('scroll', this.handleScroll);


    console.log("defefr")

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
      const videoEl = this.$refs.myVideo1;
      videoEl.play();
      videoEl.addEventListener('ended', () => {
        // 视频播放结束后，等待10秒（10000毫秒）
        setTimeout(() => {
          // 重置视频播放位置到开始，并播放
          videoEl.currentTime = 0;
          videoEl.play();
        }, 10000);
      });
    }else{
      this.model=false
      const videoEl = this.$refs.myVideo;
      videoEl.play();
      videoEl.addEventListener('ended', () => {
        // 视频播放结束后，等待10秒（10000毫秒）
        setTimeout(() => {
          // 重置视频播放位置到开始，并播放
          videoEl.currentTime = 0;
          videoEl.play();
        }, 10000);
      });
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {



    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Enterprise internal management knowledge GPT development service _ enterprise management GPT - Oil & Gas GPT',
        pageDescription: 'Oil & Gas GPT Cloud Platform（www.oilgasgpts.com）提供Enterprise Management Knowledge GPT Development Services，based on large model GPT capabilities, building Andongtong GPT, embedded in the group\'s domain document knowledge, intelligent Q & A dialogue, employees can quickly and conveniently obtain knowledge.',
        pageKeywords: 'Knowledge GPT, Management GPT, Enterprise Management GPT, Knowledge GPT Development, Oil & Gas, Oil & Gas GPT, Oil & Gas GPT Cloud Platform',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:99;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/wisdomBanner.png");
      background-position: right center; /* 靠右对齐 */
      background-size: auto 100%; /* 左侧填充 */
      background-color: #e4ebf9;
      position: relative;
      background-repeat: no-repeat;
      //background-color: transparent;

      .layer2 {
        margin-top: 4vh;
        margin-left:2vw;

        .title1 {
          font-weight: bolder;
          color:#282d7d;
          line-height: 6vh;
          width: fit-content;
          height: 7.77626699629172vh;
          font-size: 2.3vw;
          font-family:'Times New Roman';
          //font-weight: 400;
          //color: #2f6eff;
          //line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 3vh;
          width: 35vw;
          height: fit-content;
          text-align: left;
          font-size: 1.3vw;
          font-family:'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }
        .title4 {
          margin-top: 1vh;
          width: 35vw;
          font-size: 1vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          line-height:5vh;
        }
        .titlle3{
          position: absolute;
          bottom: 7.78739184177998vh;
          margin-top: 4.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #ffffff;

          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;


        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 10.09270704573548vh;
        text-align: center;
        font-size: 2.3vw;
         font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height:8vh;


      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 58.45869439630272vw;
        font-size: 1.4vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.1vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:45vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 0 2.5248454883vh 3vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 50%;
              .title1{
                margin-top: 4vh;
                font-size: 1vw;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                line-height: 3.194066749vh;
                font-size:  1.4vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 9vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.4vw;
                height: 2.8vw;
                display: flex;
                align-items: center;

                font-weight: 700;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.4vw;
                font-weight: bold;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 1.2vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
           //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/knowledgeBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left:0;
        .title1 {
          padding:0 15px;
          margin:25px auto;
          width: fit-content;
          text-align: center;
          height: fit-content ;
          font-size: 23px;
          font-family: 'Times New Roman';
          font-weight: 500;
          color: #111111;
          line-height:26PX;
        }

        .title2 {
          margin: 25px auto 0 auto;
          width: 90%;
          text-align: center;
          height: fit-content;
          font-size: 12px;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #111111;
          line-height: 22px;
        }
        .title3 {
          margin: 18px auto;
          width: 80%;
          text-align: left;
          height: 17px;
          font-size: 12px;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #111111;
          line-height: 24px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
         font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            background: white;
            border-radius: 9px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 430px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -105px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
