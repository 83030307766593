var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model===false)?_c('div',[_c('div',{ref:"total",staticClass:"totalmakeHole"},[_c('div',{staticClass:"topback",style:({ height: '6vh', backgroundColor: _vm.scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'})},[_c('headtip',{attrs:{"changeVisable":_vm.changeVisable},on:{"showDialog":_vm.showPartner}})],1),_c('div',{staticClass:"makeHole1",on:{"click":_vm.changeheadVisable}},[_c('div',{staticClass:"background"},[_c('kefu',{on:{"toptop":_vm.topTop}}),_c('div',{staticClass:"titlle1"},[_vm._v("数智油田GPT开发应用项目服务")]),_c('div',{staticClass:"title4"},[_vm._v("利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。")]),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"background"},[_c('kefuMobile',{on:{"topMobile":_vm.topTopmobile}}),_c('headtip',{on:{"showDialog":_vm.showPartner}}),_vm._m(4),_c('div',{staticClass:"title3",on:{"click":function($event){return _vm.clickConsult('Oil & Gas Generic AI')}}},[_vm._v(" Product Inquiry ")])],1),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧生产集控")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 通过大模型人工智能，实现井群智能化生产的预测、计划、排产、以及调度，实现提高生产的综合采收率效果。 "),_c('li',{staticStyle:{"margin-top":"15px"}},[_vm._v("智能化生产预测")]),_c('li',[_vm._v("智能化生产排产")]),_c('li',[_vm._v(" 单井生产智能化调控")]),_c('li',[_vm._v("智能化井群调度")]),_c('li',[_vm._v("智能化生产监控")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧能控中心")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 实现油田处理场站的智能管理和监控，电网、水网管理系统的智能优化和调配，提升能源使用效率。 "),_c('li',{staticStyle:{"margin-top":"15px"}},[_vm._v(" 电网智能化动态监控")]),_c('li',[_vm._v(" 发电机智能化动态管理")]),_c('li',[_vm._v(" 智能化电网负载均衡系统")]),_c('li',[_vm._v("水务动态智能化管理")])])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application3.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧场站升级解决方案")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application1.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 智能生产调控：智能调节控制场站智能化设备，最优化排产； 智能人机交互：通过AI人机交互系统，实现智能化控制交互。 "),_c('li',{staticStyle:{"margin-top":"15px"}},[_vm._v(" 智能生产调控系统")]),_c('li',[_vm._v(" 一体化智能排产")]),_c('li',[_vm._v(" AR 智能作业")]),_c('li',[_vm._v(" 智慧用能控制")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧管网升级解决方案")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 基于客户具体场景需求，结合算力服务厂商与基础大模型厂商相关技术规范，安东提供算力优化适配服务，最大程度发挥大模型效果与价值。 ")])]),_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application2.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"titlle1"},[_vm._v("数智油田GPT开发应用项目服务")]),_c('div',{staticClass:"title4"},[_vm._v("利用大模型人工智能技术，与目前的数智油田全场景结合，赋能数智油田全面升级。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧生产集控")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 通过大模型人工智能，实现井群智能化生产的预测、计划、排产、以及调度，实现提高生产的综合采收率效果。 "),_c('li',[_vm._v("智能化生产预测")]),_c('li',[_vm._v("智能化生产排产")]),_c('li',[_vm._v(" 单井生产智能化调控")]),_c('li',[_vm._v("智能化井群调度")]),_c('li',[_vm._v("智能化生产监控")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧能控中心")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application3.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 实现油田处理场站的智能管理和监控，电网、水网管理系统的智能优化和调配，提升能源使用效率。 "),_c('li',[_vm._v(" 电网智能化动态监控")]),_c('li',[_vm._v(" 发电机智能化动态管理")]),_c('li',[_vm._v(" 智能化电网负载均衡系统")]),_c('li',[_vm._v("水务动态智能化管理")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧场站升级解决方案")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application1.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 智能生产调控：智能调节控制场站智能化设备，最优化排产； 智能人机交互：通过AI人机交互系统，实现智能化控制交互。 "),_c('li',[_vm._v(" 智能生产调控系统")]),_c('li',[_vm._v(" 一体化智能排产")]),_c('li',[_vm._v(" AR 智能作业")]),_c('li',[_vm._v(" 智慧用能控制")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake1"},[_c('div',{staticClass:"heighLight"},[_vm._v("智慧管网升级解决方案")]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"imgDesign"},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/application2.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v(" 基于客户具体场景需求，结合算力服务厂商与基础大模型厂商相关技术规范，安东提供算力优化适配服务，最大程度发挥大模型效果与价值。 ")])])])])])])
}]

export { render, staticRenderFns }