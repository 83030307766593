<template>

  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
        <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
      </div>
        <div class="makeHole1" @click="changeheadVisable">
          <kefu @toptop="topTop"></kefu>
<!--          <div class="screen1" style="position: relative">-->

<!--            &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--            <div class="layer2">-->
<!--              <div v-sliden-instep="{duration:800}" class="title1" style="font-size: unset;line-height: unset"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>-->
<!--              <div v-sliden-instep="{duration:1200}" class="title1" style="height: unset;line-height: unset;margin-top: 2vh;font-size: 2.4vw;color: #282d7d;font-weight: bolder">Smart Security Solutions</div>-->
<!--              <div v-sliden-instep="{duration:1600}"  class="title2" style="font-size: 1.3vw;margin-top: 3vh;font-weight: bolder">Establish an all-weather integrated security system to reduce operational costs and ensure production safety</div>-->
<!--              <div v-sliden-instep="{duration:2000}"  class="title4" style="font-weight: bolder">Oil & Gas Digitalization, Simple and Practical</div>-->
<!--            </div>-->
<!--            <div v-zoom-in="{duration:800} "  style="position: absolute;bottom: 0px;font-weight: bolder"  class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">-->
<!--              Product Inquiry-->
<!--            </div>-->
<!--            &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="layer3"></div>&ndash;&gt;-->
<!--          </div>-->
          <Anxunheader :title1="'Smart Security Solutions'" :title2="'Establish an all-weather integrated security system to reduce operational costs and ensure production safety'" @clickConsult="clickConsult('Oil & Gas Generic AI')"></Anxunheader>
          <div class="screenmake3" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new3.png">

                </div>
                <div class="titleDesign">
                  <div class="title1">Significantly reduce security risks
                  </div>
                  <div class="title2">
                    <li>AI identifies risks and improves security initiatives；</li>
                    <li>Fast passage for people and vehicles, reducing waiting time；</li>
                    <li>Quick target locking and reduced evacuation time ；</li>
                  </div>
                </div>
              </div>
            </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new2.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Comprehensively improve personnel efficiency
                    </div>
                    <div class="title2">
                      <li>Real-time monitoring of equipment and system operation status；</li>
                      <li>One-screen overview of security situation and report generation；</li>
                      <li>Reduction of security incident response time by up to 80%；</li>
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      System is flexible and scalable
                    </div>
                    <div class="title2">
                      <li>Data integration and comprehensive security management；</li>
                      <li>Quick access to new systems and unified management；</li>
                      <li>Scalable processes to adapt to business needs；</li>
                      <li>Complete business platform with customizable functions；</li>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Smart Security Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">-->
<!--              As the intelligent era arrives, the needs for security monitoring in oilfields are growing. From the perspective of oilfield QHSE management,  <span style="color: rgb(255, 100, 0)">Anvision aims to replace human patrols with technology, improve access efficiency, ensure timely emergency response, and actively prevent risks in real-time</span>. This solution provides comprehensive management and security control to make security management more convenient, accurate, and safe with faster response times.-->
<!--            </div>-->
<!--          </div>-->
          <div class="screenmake21" >
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Blind spots and false alarms
                    </div>
                    <div class="title2">
                      There are monitoring blind spots, resulting important areas monitoring useless.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Low-grade intelligence
                    </div>
                    <div class="title2">
                      It relies heavily on manual patrols and inspections, it is inefficiency and prone to errors; only provide simple video surveillance and lacks intelligent analysis capabilities.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Slow emergency response
                    </div>
                    <div class="title2">
                      Traditional security systems often take a long time to respond after detecting abnormal situations.

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Solution Architecture</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/anxun21new.png'))" style="border-radius: 9px" src="@/assets/anxun21new.png">

                    </el-tooltip>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight">Key Features</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu01.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time monitoring and AI recognition
                    </div>
                    <div class="title2">
                      Automatically detecting and alarming potential risks.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu02.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Immediate alerts
                    </div>
                    <div class="title2">
                      Notify personnel immediately in case of abnormalities.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;height: 4.5vh" src="@/assets/anxunu06.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Automated inspection
                    </div>
                    <div class="title2">
                      Regularly checking equipment for faults and hazards.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:2000}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">QHSE management platform
                    </div>
                    <div class="title2">
                      Managing safety risks from tracking to reporting.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu07.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Precise personnel tracking
                    </div>
                    <div class="title2">
                      Locating personnel in emergencies for quick rescue.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.svg">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Smart firefighting
                    </div>
                    <div class="title2">
                      Using robots for fast fire extinguishing and rescue.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun7.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Drone defense
                    </div>
                    <div class="title2">
                      Preventing illegal drones from entering.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:2000}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu08.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">VR training
                    </div>
                    <div class="title2">
                      Enhancing safety awareness and emergency response skills.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                          <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>

                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-zoom-in="{duration:800}" class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:30px;margin-top: 15px;color:black">Smart Security</div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:16px;line-height:26px;margin-top:15px;color:black">Establish an all-weather integrated security system to reduce operational costs and ensure production safety</div>

            </div>
            <div  class="title4" style="font-weight:bolder">Oil & Gas Digitalization, Simple and Practical</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')"  style="font-weight:bolder;width: 170px;">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Smart Security Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">-->
<!--              As the intelligent era arrives, the needs for security monitoring in oilfields are growing. From the perspective of oilfield QHSE management, <span style="color: rgb(255, 100, 0)">Anvision aims to replace human patrols with technology, improve access efficiency, ensure timely emergency response, and actively prevent risks in real-time</span>. <br> This solution provides comprehensive management and security control to make security management more convenient, accurate, and safe with faster response times.-->
<!--            </div>-->

<!--          </div>-->
          <div class="screenmake3" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      System sustainably iterates and upgrades, continuously improving
                    </div>
                    <div class="title2">
                      <li>Data integration and comprehensive security management；</li>
                      <li>Quick access to new systems and unified management；</li>
                      <li>Scalable processes to adapt to business needs；</li>
                      <li>Complete business platform with customizable functions；</li>
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new2.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Full automation for increased staff efficiency
                    </div>
                    <div class="title2">
                      <li>Real-time monitoring of equipment and system operation status；</li>
                      <li>One-screen overview of security situation and report generation；</li>
                      <li>Reduction of security incident response time by up to 80%；</li>
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new3.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Improved user experience and sense of security
                    </div>
                    <div class="title2">
                      <li>Smart AI recognition and multi-factor access authorization；</li>
                      <li>Fast passage for people and vehicles, reducing waiting time；</li>
                      <li>Quick target locking and reduced evacuation time ；</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake21" style="padding-top: 20px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Blind spots and false alarms
                    </div>
                    <div class="title2">
                      There are monitoring blind spots, resulting important areas monitoring useless.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Low-grade intelligence
                    </div>
                    <div class="title2">
                      It relies heavily on manual patrols and inspections, it is inefficiency and prone to errors; only provide simple video surveillance and lacks intelligent analysis capabilities.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Slow emergency response
                    </div>
                    <div class="title2">
                      Traditional security systems often take a long time to respond after detecting abnormal situations.

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Solution Architecture</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun21new.png">

                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake2" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Key Features</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu01.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Real-time monitoring and AI recognition
                    </div>
                    <div class="title2">
                      Automatically detecting and alarming potential risks.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu07.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Precise personnel tracking
                    </div>
                    <div class="title2">
                      Locating personnel in emergencies for quick rescue.
                    </div>
                  </div>
                </div>


              </div>

              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">QHSE management platform
                    </div>
                    <div class="title2">
                      Managing safety risks from tracking to reporting.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu06.png">
                  </div>
                  <div class="titleDesign" >
                    <div class="title1">Automated inspection
                    </div>
                    <div class="title2">
                      Regularly checking equipment for faults and hazards.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 200px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu08.png">
                  </div>
                  <div class="titleDesign" style="padding: 0">
                    <div class="title1">VR training
                    </div>
                    <div class="title2" style="margin-top: 0">
                      Enhancing safety awareness and emergency response skills.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 200px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;height: 40px;margin-top: 2.5px" src="@/assets/anxunu02.png">
                  </div>
                  <div class="titleDesign" style="padding: 0">
                    <div class="title1">Immediate alerts
                    </div>
                    <div class="title2" style="margin-top: 0">
                      Notify personnel immediately in case of abnormalities.
                    </div>
                  </div>
                </div>


              </div>


              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 180px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun7.png">
                  </div>
                  <div class="titleDesign" style="padding: 0">
                    <div class="title1" style="height: 30px"> Drone defense
                    </div>
                    <div class="title2" style="margin-top: 0">
                      Preventing illegal drones from entering.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 180px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;width:  40px" src="@/assets/anxunu04.svg">
                  </div>
                  <div class="titleDesign" style="padding: 0">
                    <div class="title1" style="height: 30px">Smart firefighting
                    </div>
                    <div class="title2" style="margin-top: 0">
                      Using robots for fast fire extinguishing and rescue.
                    </div>
                  </div>
                </div>


              </div>


            </div>

          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-zoom-in="{duration:800}" class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div v-zoom-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  components: {Anxunheader, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,

      title:'Oil & Gas Generic AI',

      showModal: false,
      largeSrc:'',

      pageTitle: 'Smart security solution_Anvision - Oil & Gas Communication GPT',
      pageDescription: 'Anvision Smart Security Solution is designed based on the perspective of oilfield HSSE management and provides comprehensive management support and comprehensive safety assurance, including special equipment management, special operation management, and contractor management; comprehensive safety control: safety education, risk classification management and control, Hidden hazard investigation and management, major hazard source management, key site monitoring, and hazardous chemicals supervision.',
      pageKeywords: 'Smart security, risk investigation and hidden danger management, firefighting robots, special operation control, precise personnel positioning platform, smart AI security, aircraft countermeasures, smart AI inspection, smart training and emergency simulation drills, smart firefighting, Anvision, Oil & Gas Pass GPT',

      model:false,   scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/anxun22.png"),
        title1:"Smart Security",
        title2:"<li style='margin-bottom: 1vh'>Smart Security Project in China</li><li>Smart Security Construction  for an Oilfield in Iraq </li>",
      },{
        img:require("@/assets/anxun23.png"),
        title1:"Smart Security",
        title2:"<li style='margin-bottom: 1vh'>Smart Security Project in China</li><li>Smart Security Construction  for an Oilfield in Iraq </li>",
      },
      ]
    }

  },

  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Smart security solution_Anvision - Oil & Gas Communication GPT',
        pageDescription: 'Anvision Smart Security Solution is designed based on the perspective of oilfield HSSE management and provides comprehensive management support and comprehensive safety assurance, including special equipment management, special operation management, and contractor management; comprehensive safety control: safety education, risk classification management and control, Hidden hazard investigation and management, major hazard source management, key site monitoring, and hazardous chemicals supervision.',
        pageKeywords: 'Smart security, risk investigation and hidden danger management, firefighting robots, special operation control, precise personnel positioning platform, smart AI security, aircraft countermeasures, smart AI inspection, smart training and emergency simulation drills, smart firefighting, Anvision, Oil & Gas Pass GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    //width: 100%;
    //height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
      background-image: url("../../assets/banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle3{
        //margin-top: 2.07911001236094vh;
        margin:6vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
      .layer2 {
        position: absolute;
        top: 16.1495673671199vh;
        left: 8.17157712305026vw;


        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 37vw;
          //text-align: justify;
          height: fit-content;
          font-size:0.9597920277vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: black;
          line-height: 3.51520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.3060658579vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw 0 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

    }
    .screenmake21 {
      padding: 7.78739184177998vh 10vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:18vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height:8vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
                height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content; ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;

            }
            .titleDesign{
              padding: 8.5248454883vh 0.2vw 2.5248454883vh 3vw;
              margin:0 1vw auto auto;
              color: black;
              width: 45%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 2vh;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5/;
            height:15vh ;
            overflow:hidden;
            .imgDesign{
              width: 5vh;
              height:5vh;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              width: 85%;
              float: right;
              .title1{
                margin-top: 0;
                font-size: 1vw;
                display: flex;
                //justify-content: center;
                align-items: center;
                height: 2vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 2vh;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            position: relative;
            border: white 1px solid;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:35vh;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 2vw;

              height: 30vh;
              margin:3vh auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1vw;
                line-height: 3vh;
                font-weight: bold;
              }
              .title2{
                margin: 2vh auto;
                width: fit-content;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0;
              margin:auto auto ;
              color: white;
              width: fit-content;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 57vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 620px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width:120px;
          font-size: 3.50606585788562vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: 'Times New Roman';
          font-weight: bold;
          color: #4B5A8B;
          line-height: 20px;
        }



      }
      .title4{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      //z-index:: 10;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw  0  4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        padding:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        color: #2C3E50;
        line-height: 20px;
        font-weight: bold;
      }

    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:20px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{

      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 15px;
            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;

          }
          .titleDesign{
            padding: 10px 0 10px 0;
            margin-top: 5px;
          }
          .back{
            background: white;
            border-radius: 9px;
            padding: 10px 10px;
            position: relative;
            height:230px;
            margin-top: 10px;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin: 0 auto 0px auto;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.4vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            text-align: center;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
          }
          .title2{
            margin-top: 10px;
            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            padding: 15px 10px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:194px;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:350px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -75px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
