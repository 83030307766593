<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>

        <div class="makeHole1" @click="changeheadVisable">
          <kefu @toptop="topTop"></kefu>
<!--          <div class="background">-->

<!--            &lt;!&ndash;     文字&ndash;&gt;-->
<!--            <div v-zoom-in="{duration:800}" class="titlle1">Formation Monitoring Solutions </div>-->
<!--            <div v-zoom-in="{duration:800}"  class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>-->
<!--            <div v-zoom-in="{duration:800}"  class="titlle3" @click="dialogVisible=true">-->
<!--              Solution Consulting-->
<!--            </div>-->


<!--          </div>-->
          <subordianteheader :imagepath="'5'" :title1="'Formation Monitoring Solutions'" :title2="'*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.'" @dialogVisible="dialogVisible=true"></subordianteheader>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Formation Monitoring Services Overview</div>
            <div v-sliden-in="{duration:800}" class="heightLight2">Anton Deep Earth IntelliLight specializes in customized and systematic data acquisition and interpretation for the entire oil & gas development process, encompassing fiber optic monitoring, digital rock technology, reservoir monitoring, and well logging.
            </div>
            <div v-sliden-in="{duration:800}" class="heightLight3">Stratigraphic Monitoring</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div v-sliden-in="{duration:800}" class="grid0">
              <div class="card">
                <div class="heightLight3">Fiber Optic Monitoring</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Digital Rock Technology</div>
              </div>
              <div class="card">
                <div class="line"></div>
                <div class="heightLight3">Reservoir Inspection Technology</div>
              </div>
              <div class="card">
                <div class="heightLight3">Well Logging Technology</div>
              </div>


            </div>

          </div>
          <div class="screenmake21" style="padding:0 11.0976314269vw 7.7873918418vh">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 1.7vw;font-weight: unset">  Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/realtime.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Real-time and Accurate Wellbore Data
                    </div>
                    <div class="title2">
                      Key data such as temperature, pressure, and sound waves cannot be obtained in a timely manner, making it difficult to respond to downhole changes promptly.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Leakage Points are Difficult to be Detected and Accurately Located in a Timely Manner
                    </div>
                    <div class="title2">
                      This increases safety and environmental risks and affects maintenance efficiency and cost control.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Wellbore Integrity Risks are Difficult to Monitor
                    </div>
                    <div class="title2">
                      It affects safety and operation and makes it impossible to detect problems such as wellbore leakage and structural damage in a timely manner.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:2000}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Intelligent Analysis Tools
                    </div>
                    <div class="title2">
                      It is difficult to extract valuable insights from a large amount of data, and it is impossible to optimize the production system, resulting in low recovery factor.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:2400}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/cost.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">High Maintenance and Operation Costs
                    </div>
                    <div class="title2">
                      Problems cannot be found and dealt with in a timely manner, resulting in increased maintenance costs and downtime.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:2800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Traditional Monitoring Methods are Insufficient
                    </div>
                    <div class="title2">
                      Unable to meet the monitoring needs of efficient, safe, and intelligent Oil & Gas production, affecting the overall operational efficiency.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">1</span>
              <span>&nbsp;Intelligent Real-Time Fiber Optic Dynamic Monitoring Of Gas Storage Wellbore Integrity</span>
            </div>
            <div class="Light">Deploying intelligent fiber optic monitoring system to provide real-time monitoring and early warning of wellbore leakage, abnormal annular protection fluid level, tubing vibration, downhole pressure and injection and production dynamics.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Dynamic monitoring map of the depth of the annular air protection fluid level surface</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring map of leakage point</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">2</span>
              <span>&nbsp;&nbsp;Intelligent Monitoring Of Fiber Optic Leakage Points</span>
            </div>
            <div class="Light">Intelligent monitoring of fiber optic leaks includes real-time monitoring of distributed temperature acoustic signals, low-frequency strain signals, early warning and positioning of leaks, vibration evaluation of pipe columns and analysis of leakage causes.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="screenmake3">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">3</span>
              <span>&nbsp;&nbsp;Intelligent Fiber Optic Production Monitoring</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to monitor wellbore temperature profile, acoustic vibration signals and downhole pressure in real time during production, and intelligently analyze and interpret the output data of the layers to guide the optimization of the production system and improve the recovery rate.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span style="margin-top:0.7vh;background-color: #FF6400;border-radius: 50%; width:1.7vw;font-size:1.5vw;height:1.7vw;display: flex;justify-content: center;align-items: center;color: white; ">4</span>
              <span>&nbsp;&nbsp;Intelligent Fracturing Monitoring With Fiber Optics</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to dynamically monitor the fluid and sand intake, fracture development, and fracture impact in real time during the fracturing process, and evaluate the fracturing effect.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent fracturing monitoring microseismic monitoring</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Segment cluster modification monitoring</div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake5">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1" style="margin-top: 25px">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
<!--          <div class="background">-->
<!--            &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->
<!--            <headtip @showDialog="showPartner"></headtip>-->
<!--            &lt;!&ndash;     文字&ndash;&gt;-->
<!--            <div v-zoom-in="{duration:800}" class="titlle1">Formation Monitoring Solutions</div>-->
<!--            <div v-zoom-in="{duration:800}" class="title4">*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.</div>-->
<!--            <div class="titlle3" @click="dialogVisible=true">-->
<!--              Solution Consulting-->
<!--            </div>-->


<!--          </div>-->
          <sub-header-mobile :imagepath="'/gptModel71.png'" :back-height="700" :title1="'Formation Monitoring Solutions'" @clickbtn1="showPartner" @clickbtn2="dialogVisible=true" ></sub-header-mobile>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Formation Monitoring Services Overview</div>
            <div v-sliden-in="{duration:800}" class="heightLight2">Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.</div>

            <div v-sliden-in="{duration:800}" class="heightLight3">Stratigraphic Monitoring</div>
            <div class="line"></div>
            <div class="line0"></div>
            <div class="grid0">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="heightLight3">Fiber Optic Monitoring</div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="line"></div>
                <div class="heightLight3">Digital Rock Technology</div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="line"></div>
                <div class="heightLight3">Reservoir Inspection Technology</div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="heightLight3">Well Logging Technology</div>
              </div>
            </div>

          </div>
          <div class="screenmake21" >
            <div v-sliden-in="{duration:800}" class="heighLight" >  Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/realtime.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Real-time and Accurate Wellbore Data
                    </div>
                    <div class="title2">
                      Key data such as temperature, pressure, and sound waves cannot be obtained in a timely manner, making it difficult to respond to downhole changes promptly.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/foucs.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Leakage Points are Difficult to be Detected and Accurately Located in a Timely Manner
                    </div>
                    <div class="title2">
                      This increases safety and environmental risks and affects maintenance efficiency and cost control.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/select.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Wellbore Integrity Risks are Difficult to Monitor
                    </div>
                    <div class="title2">
                      It affects safety and operation and makes it impossible to detect problems such as wellbore leakage and structural damage in a timely manner.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Intelligence.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Intelligent Analysis Tools
                    </div>
                    <div class="title2">
                      It is difficult to extract valuable insights from a large amount of data, and it is impossible to optimize the production system, resulting in low recovery factor.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/cost.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">High Maintenance and Operation Costs
                    </div>
                    <div class="title2">
                      Problems cannot be found and dealt with in a timely manner, resulting in increased maintenance costs and downtime.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/monitor.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Traditional Monitoring Methods are Insufficient
                    </div>
                    <div class="title2">
                      Unable to meet the monitoring needs of efficient, safe, and intelligent Oil & Gas production, affecting the overall operational efficiency.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span>①&nbsp;Intelligent Real-Time Fiber Optic Dynamic Monitoring Of Gas Storage Wellbore Integrity</span>
            </div>
            <div class="Light">Deploying intelligent fiber optic monitoring system to provide real-time monitoring and early warning of wellbore leakage, abnormal annular protection fluid level, tubing vibration, downhole pressure and injection and production dynamics.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Dynamic monitoring map of the depth of the annular air protection fluid level surface</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring map of leakage point</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span>②&nbsp;Intelligent Monitoring Of Fiber Optic Leakage Points</span>
            </div>
            <div class="Light">Intelligent monitoring of fiber optic leaks includes real-time monitoring of distributed temperature acoustic signals, low-frequency strain signals, early warning and positioning of leaks, vibration evaluation of pipe columns and analysis of leakage causes.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time monitoring of steel wire fiber leakage point</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake3">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span>③&nbsp;Intelligent Fiber Optic Production Monitoring</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to monitor wellbore temperature profile, acoustic vibration signals and downhole pressure in real time during production, and intelligently analyze and interpret the output data of the layers to guide the optimization of the production system and improve the recovery rate.</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Comprehensive interpretation of fiber optic output profile</div>
                  </div>
                </div>

              </div>

            </div>

          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">
              <span>④&nbsp;Intelligent Fracturing Monitoring With Fiber Optics</span>
            </div>
            <div class="Light">Deploying intelligent fiber-optic monitoring system to dynamically monitor the fluid and sand intake, fracture development, and fracture impact in real time during the fracturing process, and evaluate the fracturing effect.</div>

            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Intelligent fracturing monitoring microseismic monitoring</div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">Segment cluster modification monitoring</div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake5">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" :current-index="currentIndex" arrow="always" interval="5000">
                <el-carousel-item v-for="(item, index) in success" :key="index">
                  <div class="card">
                    <div class="back">
                      <div v-zoom-in="{duration:800}" class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div v-zoom-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title1">{{item.title2}}</div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--          <el-button class="arrowButtonleft" @click="leftnext" :disabled="currentIndex === 0">-->
              <!--            <i class="el-icon-arrow-left"></i>-->
              <!--          </el-button>-->
              <!--          <el-button class="arrowButtonright" @click="rightnext" :disabled="currentIndex === success.length - 1">-->
              <!--            <i class="el-icon-arrow-right"></i>-->
              <!--          </el-button>-->
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <resultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></resultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import resultTip from "@/components/ResultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import subordianteheader from "@/views/subordinate/components/subordianteheader.vue";
import SubHeaderMobile from "@/views/subordinate/components/subHeaderMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
  },
  name: "",

  props: [],

  components: {SubHeaderMobile, PartnerTip, resultTip, headtip,bottomTip,kefu,kefuMobile,subordianteheader},

  data() {

    return {
        changeVisable:1,
      pageTitle: 'Formation monitoring solution_GPT application project service for Oil & Gas development operations-Oil & Gas Communication GPT',
      pageDescription: 'Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.',
      pageKeywords: 'Formation monitoring, optical fiber monitoring technology, digital rock technology, Oil & Gas reservoir detection technology, logging technology, Oil & Gas development operation GPT, Oil & Gas communication GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      currentIndex: 0 ,
      success:[{
        img:require("@/assets/success9.jpg"),
        title1:"Fiber Optic Leakage Monitoring GPT",
        title2:"DAS Analysis",
        title3:"Accurately determine the location of leaks based on temperature and sound information.",
             },{
        img:require("@/assets/success10.png"),
        title1:"Intelligent Fracturing GPTs System",
        title2:"DTS Analysis",
        title3:"Based on sound intensity and frequency band information, evaluate the shape and size of leakage point and give suggestions for leakage point repair program.",

      },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
       window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    leftnext() {
      if (this.currentIndex > 0) {
        this.currentIndex--; // 减少索引以向左移动
      } else {
        // 如果 currentIndex 已经是0，则将其设置为最后一个索引
        this.currentIndex = this.success.length - 1;
      }
    },
    rightnext() {
      if (this.currentIndex < this.success.length - 1) {
        this.currentIndex++; // 增加索引以向右移动
      } else {
        // 如果 currentIndex 已经是最后一个索引，则将其设置为0
        this.currentIndex = 0;
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
 handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }

  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Formation monitoring solution_GPT application project service for Oil & Gas development operations-Oil & Gas Communication GPT',
        pageDescription: 'Anton Deep Earth Smart Light Data Technology features customized and systematic data acquisition and interpretation services for the whole process of Oil & Gas development, and its business includes fiber-optic monitoring technology, digital rock technology, Oil & Gas reservoir monitoring technology, well logging technology.',
        pageKeywords: 'Formation monitoring, optical fiber monitoring technology, digital rock technology, Oil & Gas reservoir detection technology, logging technology, Oil & Gas development operation GPT, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/menage1.jpg");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2vw;
         font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 8.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 3vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;
        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .heightLight3{
        margin: 2vh auto 0 auto;
        //cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #2f6eff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 2.1989601386vw;
        height:7.5624227441vh;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line{
         height: 5.831891223733vh;
         width: 4px;
         left: 50%;
        margin:0 auto;
         background-color: #B3D0FF;
         margin:0 auto;
       }
      .line0{
        width:45.7vw;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 5.831891223733vh;
        margin:0 auto;
      }
      .grid0{
        display: grid;
        width: 60vw;
        margin:0 auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height:5.831891223733vh;
            width: 4px;
            left: 50%;
            top:-5.831891223733vh;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }

          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: 'Times New Roman';
            font-weight: 500;
            color: #2f6eff;
            line-height: 3.3374536465vh;
            text-align: center;
            border-radius: 9.1px;
            width: fit-content;
            padding: 2.4624227441vh 0.5vw;
            height:8.5624227441vh;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }
      .grid{
        margin-top:4.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 1vw;
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:30.72311495673671vh ;
            background: #2F6EFF linear-gradient(to right, #CFE1FF 0%, #F0F6FF 100%);
            display: flex;

            .titleDesign{
              .Title1{
                text-align: center;
                margin:5.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin:4.8124845488vh 2.5597920277vw 1.1124845488vh 2.155401502vw;
                text-align: justify;
                height: 4.6971569839vh;
                font-size:0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                //color:#2F6EFF;
                line-height: 3.194066749vh;
              }
            }
          }



        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background: #FFFFFF;
            margin-bottom: 1.5248454883vh;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              height:54vh;
              width: 100%;
              background-image: url("../../assets/serve1.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              background-color: #2F6EFF;
              .Title1{
                color: white;
                text-align: center;
                font-size: 1vw;

                 font-family: 'Times New Roman';
                font-weight: 700;
              }

            }
            .backconsult{
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left:5.55414091470952vw ;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 50%;
              transform: translateX(-50%);
            }

          }

        }

      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;
      }
      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;
      }
      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) ;
        column-gap: 2vw;
        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 5vh;

      }

      .Light{
        margin:2vh auto 0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }.grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 29.5vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 29.5vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:5.4vh;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          width:100%;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 60vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          width: 100%;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 60vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake3{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background:#ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 35vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage10.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 1vw;
                margin-top: 2.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 35vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                margin-left: 10vw;
                margin-top: 2.8124845488vh;

                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 3.194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake4{
      padding:5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:6.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 4.23733003708282vh;

      }

      .Light{
        margin:0 auto;
        text-align: center;
        height: 8.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height:3.5vh;

        width: 66.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        //margin-top:3.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        //column-gap:2.21317157712305vw ;
        .card{
          border-radius: 9px ;;
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              //border:1px solid blue;
              background-image: url("../../assets/menage11.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:fit-content;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

        .card1{
          // cursor: pointer;
          height:fit-content ;
          padding:0.9888751545vh 0.5243212016vw 0.9888751545vh 0.3932409012vw;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            box-shadow: 5px 0 14px 0px #D4E4FF;
             height:fit-content;
            background: #FFFFFF ;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height: 33.06056860321384vh;
              opacity: 1;
              background-image: url("../../assets/menage12.jpg");
              background-position: center;
              background-size:cover;
              //border:1px solid blue;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign{
              padding: 5.2124845488vh 5.5597920277vw 5.1124845488vh 5.155401502vw;
              .Title1{
                text-align: center;
                height:5.4vh;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1vw;
                 font-family: 'Times New Roman';
                font-weight: 700;
                line-height: 2.7194066749vh;
              }
              .Title2{
                text-align: justify;
                margin-top: 3.8124845488vh;
                height: 4.6971569839vh;
                font-size: 0.8243212016vw;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 2.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          //// cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto 0 auto 0;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                margin-top:25px;
                line-height: 4vh;
                font-size: 0.8243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                //column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 460px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/gptModel71.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin:50px auto;
        width: fit-content;
        text-align: center;
        height: 23px;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 32px;
      }
      .titlle3{
        position: absolute;
        bottom:75px;
        left:50%;
        transform: translateX(-60%);
        font-size:12px;
         font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      .title4{
        position: absolute;
        left:10%;
        bottom:-85px;
        margin: 35px auto 0 auto;
        width: 80%;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 18px;
        border-radius: 15px;
        padding: 15px;
        background:white;
        border: #e8eefa 1px solid;
      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:90px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .line{
        height: 25px;
        width: 4px;
        left: 50%;
        margin:0 auto;
        background-color: #B3D0FF;
      }
      .heightLight3{
        margin: 0 auto 0 auto;
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #2f6eff;
        line-height: 22px;
        text-align: center;
        border-radius: 9px;
        width: fit-content;
        padding: 10px 15px;
        height:40px;
        background: #B3D0FF;
        border: #B3D0FF 1px solid;
      }
      .line0{
        width: 245px;
        border-top-right-radius: 63px;
        border-top-left-radius: 63px;
        border-top:  #B3D0FF 4px solid;
        border-left:  #B3D0FF 4px solid;
        border-right:  #B3D0FF 4px solid;;
        height: 35px;
        margin:0 auto;
      }
      .grid0{
        width: 350px;
        margin:0 auto;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;

        .card{
          position: relative;
          margin: 0 auto;
          text-align: center;
          .line{
            position: absolute;
            height: 35px;
            width: 4px;
            left: 50%;
            top:-35px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .line1{
            position: absolute;
            height: 75px;
            width: 4px;
            left: 50%;
            top:-75px;
            transform: translateX(-50%);
            background-color: #B3D0FF;
            margin:0 auto;
          }
          .heightLight3{
            margin:0 auto;

            // cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-family: 'Times New Roman';
            font-weight: 500;
            color: #2f6eff;
            line-height: 16px;
            text-align: center;
            border-radius: 9px;
            width: fit-content;
            padding: 5px 5px;
            height:45px;
            background: transparent;
            border: #B3D0FF 4px solid;
          }
        }


      }

    }

    .screenmake{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:15px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage2.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:155px;
              width: 100%;
              background-image: url("../../assets/menage3.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake2{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage4.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:285px;
              width: 100%;
              background-image: url("../../assets/menage5.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake3{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   #ebf1fd;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:225px;
              width: 100%;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:white;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage7.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background:   white;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #FF6400;
        line-height: 20px;

      }

      .Light{
        margin:19px 2vw 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        //margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr);
        .card{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage8.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
        .card1{
          // cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:10px;
          opacity: 1;
          .back{
            border-radius: 9px ;;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content ;
            overflow:hidden;
            background:#ebf1fd;
            margin-bottom: 15px;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              text-align: center;
              border:1px solid #2537a8;
              height:185px;
              width: 100%;
              background-image: url("../../assets/menage9.jpg");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 10px 1.5248454883vw;
              .Title1{
                margin: 10px;
                text-align: center;
               height:fit-content;
                font-size:14px;
                 font-family: 'Times New Roman';
                font-weight: 500;
                line-height:22px;
              }
              .Title2{
                margin: 14px 8px 20px 8px;
                text-align: justify;
                height: fit-content;
                font-size:12px;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 22px;
              }
            }


          }
        }
      }
    }
    .screenmake5{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:400px;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size:12px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:12px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -90px -10px  ;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size:12px;
        }
      }
    }
  }

}
</style>
