<script>
// import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    // slidenIn:vSlidenIn
  },
  props:{
    imagepath:{
      type:String,
      default:require('@/assets/u19.png')
    },
    title2:{
      type:String,
      default:"帮助辅助效率提升，减少和盐成本，单位可视化提高数据可读性"
    },
    title1:{
      type:String,
      default:"辅助效率提升"
    },
    imageDesign_Height:{
      type:String,
      default:'50px'
    },
    imageDesign_Width:{
      type:String,
      default:'50px'
    },
  }


}
</script>

<template>
  <div  class="card">
    <div class="back">
      <div class="imgDesign" :style="{height: imageDesign_Height, width: imageDesign_Width}">
        <img loading="lazy"  style="border-radius: 9px" :src="imagepath">
      </div>
      <div class="titleDesign" >
        <div class="title1"  v-html="title1"></div>
        <div class="title2" v-html="title2">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ebf1fd;
  padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
  opacity: 1;
  .back {
    margin-top: 15px;
    border-radius: 9px;
    position: relative;
    //height:580px ;
    overflow: hidden;

    .imgDesign {
      width: 50px;
      height: 45px;
      margin: 0 auto;
      opacity: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      .title1{
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        margin: 10px 10px 0 10px;
      }
      .title2{
        margin: 5px 10px;
        line-height: 22px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
    }


  }

}
</style>
