<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <kefu @toptop="topTop" style="z-index: 999"></kefu>
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <div class="background" style="position: relative">
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <!--     文字-->
            <div class="layer2" style="height: 100%">
            <div class="title1" v-sliden-instep="{duration:800}" style="font-weight: bolder;color: #282d7d">Sand Plugging Alarm</div>

              <div class="title2" v-sliden-instep="{duration:1200}" style="font-weight: bolder;margin-left: 0px;color: #282d7d">
                <li>Dynamic monitoring and early warning of sand plugging risks.</li>
              </div>

              <div style="display: flex;width: 30vw;margin: auto;margin-left: 0px;position: absolute;bottom: 0vh">
                <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto"  @click="clickConsult('Oil & Gas Generic AI')">
                  Product Inquiry
                </div>
                <div   class="titlle3" style="font-weight: bolder;position: unset;margin:6vh auto" @click="freeExperience">
                  Free Trial
                </div>
              </div>
            </div>

          </div>

          <div>
<!--            <div class="screenmake1">-->
<!--              <div class="heighLight">-->
<!--                Platform Capabilities-->
<!--              </div>-->
<!--              <div class="grid">-->
<!--                <div class="card">-->
<!--                  <div class="back">-->

<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Data Input and Processing Module-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        Sensors monitor plugging conditions in real time and transmit key data to an intelligent analysis and decision-making system. This data includes flow, pressure, and other relevant parameters to ensure real-time status updates.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Intelligent Analysis Module-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        The system includes a data storage and processing module equipped with advanced machine learning algorithms for intelligent analysis. The system also features an early warning module that predicts potential congestion problems and provides early warning signals. In addition, a human control module is provided so that engineers can make adjustments to the system recommendations.-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Early Warning and Decision Aid Module-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        The system sends out an early warning signal two minutes in advance when it detects the risk of sand clogging, providing a detailed report on the sand clogging situation and optimization recommendations to help engineers take effective measures to prevent sand clogging and optimize engineering operations.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="screenmake21" style="padding-top: 6vh;">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 2.1vw"> Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/interface.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">False positives and false negatives cause disruptions to business
                    </div>
                    <div class="title2">
                      Missed and false alarms can lead enterprises to miss the optimal timing in addressing sand blockage issues, thereby increasing maintenance costs and the risk of production shutdowns, which in turn affect overall operational efficiency and customer satisfaction.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Adaptability.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Insufficient adaptability
                    </div>
                    <div class="title2">
                      The accuracy of predictions decreases under different wellbore or operating conditions, and the frequent need for adjustments and optimizations increases the consumption of human resources, affecting the progress of other business operations.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/combin.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Difficulties in data integration
                    </div>
                    <div class="title2">
                      The integration of information from various sensors and data sources is complex, leading to data isolation, which affects overall business decision-making and coordination efficiency, limiting the enterprise's ability to respond flexibly.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Function Introduction</div>
            <div v-sliden-in="{duration:800}" class="heighLight1" style="font-weight: bolder">
              Intelligent Sand Plugging Early Warning Platform is based on real-time data collection and transmission, combined with deep learning and edge computing technology to realize dynamic monitoring and early warning of sand plugging risk. Through efficient data analysis, the system provides early warning and optimization suggestions, ensures accurate warning and timely response, and improves the efficiency and safety of sand plugging management. The solution helps the intelligent transformation of sand plugging management and provides a comprehensive solution to the sand plugging problem in oil & gas extraction.
            </div>
          </div>
          <div class="screenmake2" style="background-color: transparent;">
            <div v-sliden-in="{duration:800}" class="heighLight">
              Technical Features</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/plug1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Integrated Deep Learning
                    </div>
                    <div class="title2">
                      The system employs deep learning algorithms, including CNN, to process complex data, enabling precise sand plugging prediction and real-time analysis, boosting warning accuracy and response speed.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back" >
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/plug2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Efficient Data Processing and Transmission
                    </div>
                    <div class="title2">
                      The system utilizes advanced data processing technologies and edge computing to ensure the efficiency and reliability of data collection, transmission and real-time processing. The speed of data processing and analysis is greatly improved to ensure timely identification and feedback of sand blockage risks.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/plug3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Modularity and Expandability
                     </div>
                    <div class="title2">
                      The system design adopts a modular architecture, supporting flexible expansion according to user needs and actual application scenarios. Whether it is adding new sensor types or integrating additional analysis functions, the system can be easily adapted to achieve a customized sand plugging risk management solution.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake11" style="padding-top: 6vh">
            <div v-sliden-in="{duration:800}" class="heighLight">Function Experience</div>
            <div v-sliden-in="{duration:800}" class="heighLight1">Intelligent Sand Plugging Early Warning Platform covers <span style="font-weight: bold">Analysis of Sand Plug Warning</span>.You can <span style="font-weight: bold;color: red;">switch functions below, free experience</span>!  <br>If you have cooperation intention and product consultation, please <span style="color: #FF6400;cursor: pointer" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>. </div>
            <div class="grid">
              <div  class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div style="display: flex">
                      <div class="Title1">
                        1. Data Source:
                      </div>
                      <div class="Title2">
                         &nbsp;Logging data and elemental analysis data of cuttings in laboratory during drilling process.
                      </div>
                    </div>
                    <div>
                      <div class="Title1">
                        2. Operation Process:<span class="Title2" >&nbsp;Click the button 【Simulated Data Sample】 to download the sample of simulated data for sand plug (you can also modify the simulated file). Click the 【Upload】 button and select the file to be uploaded and wait for the analysis result.
                 </span>
                      </div>
                      <div class="Title2" style="display: flex">
                      </div>
                    </div>
                  </div>
                  <div ref="iframeContainer" class="iframeStyle">
                    <iframe :src="iframeUrl1" frameborder="0" allowfullscreen ></iframe> </div>
                </div>


              </div>
              <!--              <el-tab-pane label="异常数据" name="second">-->
              <!--                <div class="card">-->
              <!--                  <div class="back">-->
              <!--                    <div class="titleDesign">-->
              <!--                      <div style="display: flex">-->
              <!--                        <div class="Title1">-->
              <!--                          1. 数据来源：-->
              <!--                        </div>-->
              <!--                        <div class="Title2">-->
              <!--                          钻井数据;  测井数据;  地质参数;  钻井工程数据-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                      <div>-->
              <!--                        <div class="Title1">-->
              <!--                          2. 操作流程：<span class="Title2" >点击【模拟数据样例】，下载地层压力数据模板文件（您也可对表中数据进行修改）。点击【上传】按钮，选择上传工程文件，计算完成后单独对异常数据进行分析展示-->
              <!--                 </span>-->
              <!--                        </div>-->
              <!--                        <div class="Title2" style="display: flex">-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                      <div style="display: flex">-->
              <!--                        <div class="Title1">-->
              <!--                          *&nbsp;  注意事项：-->
              <!--                        </div>-->
              <!--                        <div class="Title2">-->
              <!--                          参数范围要根据工程规定的阈值进行设置，后续根据用户需求持续更新。-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div ref="iframeContainer" class="iframeStyle">-->
              <!--                      <iframe :src="iframeUrl2" frameborder="0" allowfullscreen></iframe> </div>-->
              <!--                  </div>-->


              <!--                </div>-->
              <!--              </el-tab-pane>-->



            </div>

          </div>
          <div>
<!--            <div class="screenmake3">-->
<!--              <div class="heighLight">-->
<!--                Technical Features-->
<!--              </div>-->
<!--              <div class="grid">-->
<!--                <div class="card">-->
<!--                  <div class="back">-->

<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Integrated Deep Learning:-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        The system utilizes deep learning algorithms to assess the risk of clogging, including Convolutional Neural Networks (CNN) and Recurrent Neural Networks (RNN). These technologies can efficiently process complex data patterns, realize accurate prediction and real-time analysis of sand plugging conditions, and improve the accuracy of early warning and response speed.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Efficient Data Processing and Transmission:-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        The system utilizes advanced data processing technologies and edge computing to ensure the efficiency and reliability of data collection, transmission and real-time processing. The speed of data processing and analysis is greatly improved to ensure timely identification and feedback of sand blockage risks.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">Modularity and Expandability:-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        The system design adopts a modular architecture, supporting flexible expansion according to user needs and actual application scenarios. Whether it is adding new sensor types or integrating additional analysis functions, the system can be easily adapted to achieve a customized sand plugging risk management solution.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="screenmake4">-->
<!--              <div class="heighLight">-->
<!--                Expected Benefits-->
<!--              </div>-->
<!--              <div class="grid">-->
<!--                <div class="card">-->
<!--                  <div class="leadleft"/>-->
<!--                  <div class="back">-->

<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">-->
<!--                        It is expected to shorten the sand plug treatment time by-->
<!--                      </div>-->
<!--                      <div class="persent">15%-25%</div>-->
<!--                      <div class="title1">-->
<!--                        and improve the treatment efficiency and operation continuity.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="leadleft"/>-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">-->
<!--                        It is expected to reduce the cost of sand clogging treatment by-->
<!--                      </div>-->
<!--                      <div class="persent">8%-12%</div>-->
<!--                      <div class="title1">-->
<!--                        and minimize the additional costs caused by sand clogging.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="card">-->
<!--                  <div class="leadleft"/>-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">-->
<!--                        It is expected to improve the success rate of sand plug management by-->
<!--                      </div>-->
<!--                      <div class="persent">10%-15%</div>-->
<!--                      <div class="title1">-->
<!--                        and reduce the operation interruption and risk caused by sand plugs.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <div class="leadleft"/>-->
<!--                  <div class="back">-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title1">-->
<!--                        Promote the digital transformation of sand plug risk management through advanced early warning and decision support system, and enhance the overall intelligent level and management efficiency of the project.-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="screenmake5">-->
<!--              <div class="heighLight" style="     margin: 6vh 0vw 2vh 0vw;-->
<!--    height: 3vh;-->
<!--    align-items: center;-->
<!--    justify-content: center;-->
<!--    display: flex;">-->
<!--                Customer Value-->
<!--              </div>-->
<!--              <div style="display: flex;margin: -9vh 0vw;">-->
<!--                <div class="fanganimg" style="float: left;height: 100%;width:57% ">-->
<!--                  <img src="@/assets/plug6.webp" style="    height: 77%;-->
<!--    width: 93%;-->
<!--    margin:14vh auto;">-->
<!--                </div>-->
<!--                <div style="display: contents">-->

<!--                  <div class="grid" style="">-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="titleDesign">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1">-->
<!--                            Improve clogging management efficiency-->
<!--                          </div>-->
<!--                          <div class="title2">-->
<!--                            Through real-time monitoring and accurate warnings, potential clogging problems can be identified in advance, shortening the processing time and minimizing the interruption of operations, thus improving the overall operational efficiency.-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1">Reduce the risk of clogging-->
<!--                          </div>-->
<!--                          <div class="title2">-->
<!--                            The system can send out timely warning signals to help engineers take prompt measures to avoid production delays and additional costs caused by clogging, and to protect the safety of equipment and personnel.-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1"> Optimize decision-making support:-->
<!--                          </div>-->
<!--                          <div class="title2">-->
<!--                            Based on comprehensive data analysis, the system provides scientific risk prediction and response strategies to assist engineers in making more accurate decisions and improve the science and effectiveness of sand plugging management.-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card">-->
<!--                      <div class="back">-->
<!--                        <div class="titleDesign">-->
<!--                          <div class="title1">-->
<!--                            Promote the intelligent transformation of the platform-->
<!--                          </div>-->
<!--                          <div class="title2">-->
<!--                            Through advanced technology and data-driven solutions, the system helps the digital transformation of sand plugging risk management, and improves the overall intelligent level and management efficiency of the project.-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>

          <bottomTip></bottomTip>
        </div>
      <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <kefuMobile @topMobile="topTopmobile" style="z-index: 999"></kefuMobile>
        <div class="screen1">
         <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Sand Plugging Alarm</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 23px;color:#2c3e50;text-align: left">
              <li>Dynamic monitoring and early warning of sand plugging risks.</li>
            </div>
            </div>

          <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')" style="font-weight: bolder">
            Product Inquiry
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>


        <div class="screenmake21" style="padding-top: 0;background-color: transparent">
          <div class="heighLight" style="height: fit-content;"> Business Pain Points and Challenges</div>

          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/interface.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">False positives and false negatives cause disruptions to business
                  </div>
                  <div class="title2">
                    Missed and false alarms can lead enterprises to miss the optimal timing in addressing sand blockage issues, thereby increasing maintenance costs and the risk of production shutdowns, which in turn affect overall operational efficiency and customer satisfaction.
                  </div>
                </div>
              </div>


            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/Adaptability.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Insufficient adaptability
                  </div>
                  <div class="title2">
                    The accuracy of predictions decreases under different wellbore or operating conditions, and the frequent need for adjustments and optimizations increases the consumption of human resources, affecting the progress of other business operations.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/combin.png" style="border-radius: 9px">
                </div>
                <div class="titleDesign">
                  <div class="title1">Difficulties in data integration
                  </div>
                  <div class="title2">
                    The integration of information from various sensors and data sources is complex, leading to data isolation, which affects overall business decision-making and coordination efficiency, limiting the enterprise's ability to respond flexibly.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="screenmake1">
          <div class="heighLight">   <span>Function Introduction</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign">

                </div>
                <div>
                  <div class="Title2" style="margin: 2vh auto;
    line-height: 3vh;">
                    Intelligent Sand Plugging Early Warning Platform is based on real-time data collection and transmission, combined with deep learning and edge computing technology to realize dynamic monitoring and early warning of sand plugging risk. Through efficient data analysis, the system provides early warning and optimization suggestions, ensures accurate warning and timely response, and improves the efficiency and safety of sand plugging management. The solution helps the intelligent transformation of sand plugging management and provides a comprehensive solution to the sand plugging problem in oil & gas extraction.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="screenmake11" style="padding: 15px 15px">
          <div class="heighLight">   <span>Technical Features</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="imgDesign">  <img src="@/assets/frackIcon1.png"></div>
                  <div>
                    <div class="Title1">Integrated Deep Learning</div>
                    <div class="Title2">
                      The system employs deep learning algorithms, including CNN, to process complex data, enabling precise sand plugging prediction and real-time analysis, boosting warning accuracy and response speed.
                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon2.png"></div>
                  <div>
                    <div class="Title1">Efficient Data Processing and Transmission</div>
                    <div class="Title2">
                      The system utilizes advanced data processing technologies and edge computing to ensure the efficiency and reliability of data collection, transmission and real-time processing. The speed of data processing and analysis is greatly improved to ensure timely identification and feedback of sand blockage risks.

                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon3.png"></div>
                  <div>
                    <div class="Title1">Modularity and Expandability</div>
                    <div class="Title2">
                      The system design adopts a modular architecture, supporting flexible expansion according to user needs and actual application scenarios. Whether it is adding new sensor types or integrating additional analysis functions, the system can be easily adapted to achieve a customized sand plugging risk management solution.
                    </div>
                  </div>

                </div>
              </div>


            </div>




          </div>
        </div>
        <div class="screenmake2" style="background: #D2E3FF !important;">
          <div class="heighLight">   <span>Function Experience</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign" style="margin-top: 0">
                  <div class="Title2" style="margin-left: 0">
                   Intelligent Sand Plugging Early Warning Platform covers <span style="color:#FF6400;">Sand plug data analysis function</span>.You can go to the computer to experience the free functions!<br>Experience link (PC version): <br><span style="border-bottom: #333333 1px solid">https://www.oilgasgpts.com/plug</span><br>
                    If you have cooperation intention and product consultation, please <span style="color:red" @click="clickConsult('Oil & Gas Generic AI')">contact us</span>！
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>
</template>
<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
// import consultTip from "@/components/consultTip.vue";
// import PartnerTip from "@/components/partnerTip.vue";
// import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  // components: {PartnerTip, consultTip, headtip,bottomTip},
  components: {kefu, PartnerTip, consultTip, headtip,bottomTip,kefuMobile},

  data() {

    return {
      changeVisable:1,
      title:"",

      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      model:false,
      activeName: 'first',
      userId:localStorage.getItem("userId"),
      iframeUrl1:"https://ab.oilgasgpts.com/frackEn/zhnshdyjmx",
      // iframeUrl1:"https://ab.oilgasgpts.com/params-show",
      // iframeUrl2:"https://ab.oilgasgpts.com/params-error",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

  methods: {
    freeExperience(){
      // 获取 ref 为 'freeplay' 的元素
      const freeplaySection = this.$refs.freeplay;
      // 使用 scrollIntoView 定位到该元素
      freeplaySection.scrollIntoView({ behavior: 'smooth' });
    },
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
 const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    clickConsult(item){
      // console.log("联系联系")
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
     changeheadVisable(){
       if(this.changeVisable===1){
         this.changeVisable=0
       }
       else{
         this.changeVisable=1
       }
       console.log("父组件", this.changeVisable)
     },
    handleScroll() {
      this.scrollHeight = this.$refs.total.scrollTop;


      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 350) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);


    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    document.title='Intelligent Sand Plugging Early Warning Platform';
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Intelligent Sand Plugging Early Warning Platform',
        pageDescription: 'Intelligent sand plugging early warning platform is based on real-time data collection and transmission, combined with deep learning and edge computing technology to realize dynamic monitoring and early warning of sand plugging risk. Through efficient data analysis, the system provides early warning and optimization suggestions, ensures accurate warning and timely response, and improves the efficiency and safety of sand plugging management. The solution helps the intelligent transformation of sand plugging management and provides a comprehensive solution to the sand plugging problem in oil & gas extraction.',
        pageKeywords: '工艺类GPTs，智能钻井地层分析平台，智能注采决策，油气田视频监控，地层压力异常工况报警平台，人工举升优化，机采智能管控，油气田能耗优化，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',
      };
      shareUrl(shareData);
    }, 1000);

  },
}

</script>

<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 5.86077411900635vw;
      width: 100%;
      height: 72.41285537700865vh;
      background-image: url("../../assets/gpt21.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .layer2 {
        margin-top: 10vh;
        margin-left: 2vw;

        .title1 {
          width: fit-content;
          //height: 7.77626699629172vh;
          font-size: 2.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 2.63733003708282vw;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.3vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.8vw;
        }

        .title4 {
          margin-top: 3vh;
          width: 40vw;
          font-size: 1vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: 400;
          line-height: 7vh;
        }

        .titlle3 {
          position: absolute;
          bottom: 7.78739184177998vh;
          margin-top: 4.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2vw;
          font-family:  "Times New Roman",Sarial, sans-serif;
          font-weight: bolder;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 0px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          //border: #2168DB 1px solid;
          background: orange;
          //background: rgba(255, 255, 255, 0.5);
        }
      }
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 2.3vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }
      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 62vw;
        font-size: 1.4vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .title2 {
        text-align: left;
        margin: 3.82076637824475vh auto 0 auto;
        width: fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:#111111;
        line-height: 4.31520395550062vh;
      }
      .titlle3 {
        margin: 6.07911001236094vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .screenmake0{
      padding:4.8430160692vh  9vw 4.8430160692vh 9vw ;
      width: 100%;
      height:  fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        line-height: 3.5vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap:0 ;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw ;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            display: flex;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height:40vh ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              height:40vh;
              width: 500vh;
              margin:0vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              margin:0 1vw ;
              .Title1{
                width: 30vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1.4vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
              .Title2{
                // display: flex;
                // flex-direction: column;
                // justify-content: center; /* Center vertically */
                // align-items: flex-start;
                // color:#111111;
                // text-align: justify;
                // font-size: 1.2vw;
                // height: fit-content;
                // font-family: 'Times New Roman';
                // font-weight: 400;
                // line-height: 4vh;
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color:#111111;
                text-align: center;
                font-size: 1.4vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 4.7194066749vh;
              }
            }
          }
        }

      }
    }
    .screenmake1 {
      padding: 0.1vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }
      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 38vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size:  2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:   minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      padding: 3vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .heighLight1{
        text-align: left;
        margin: 0 auto;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }
      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr)  minmax(0,1fr);
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .titleDesign{
            z-index: 999;
            margin:3vh 0 0 1.5vw;
            padding:  0 1.5vw;
            color: black;
            width: 100%;
            float: right;
            .title1{
              margin: 0 auto;
              text-align: center;
              font-size: 1.4vw;
              font-weight: bold;
            }
            .title2{
              z-index: 999;
              margin-top: 2vh;
              text-align: left;
              font-weight: 400;
              font-size: 1.2vw;
              line-height: 3vh;
            }
            .title3{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);

              margin-top: 2vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }

          }
          .back         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            background-color:#ebf1fd;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100% ;
            padding: 0 0 3vh 0;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:25vh;
            }


          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;
      background-image: url("../../assets/plug5.webp");
      background-size: cover;
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;
        color: white;
      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .back {
            opacity: 1;
            //background: white;
            //border-radius: 9px;
            //display: flex;
            position: relative;
            //border: 1px solid white;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;
            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {

              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                color: white;
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                color: white;
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;
      //background-image: url("../../assets/plug5.webp");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;
        //color: white;
      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        .card {
          .leadleft{
            //width: 2vw;
            //background-color: brown;
          }
          border-left:  brown 0.5vw solid;
          //cursor: pointer;
          display: flex;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .back {
            opacity: 1;
            //background: white;
            //border-radius: 9px;
            //display: flex;
            position: relative;
            //border: 1px solid white;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 25vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;
            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }
            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              //margin-top: 20px;
              .persent{
                font-weight: bolder;
                font-size:5vh;
                color: #eb7c31;
              }
              .title1 {
                //color: white;
                margin: 2vh auto;
                text-align: left;
                font-size: 1.05vw;
                font-weight: bold;
                line-height: 2vw;
              }

              .title2 {
                //color: white;
                margin: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake5 {
      padding: 0vh 7vw;
      width: 100%;
      height: 78vh;
      //height: 50vh;
      //height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

      .grid {
        margin-top: 5.787392vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr);
        grid-template-rows: 9vh 14vh 14vh 14vh;
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 3vh auto;
              color: black;
              height: 20vh;
              width: 100%;
              float: right;

              .title1 {
                margin: 0 auto;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 0;
                width: 69vh;
                line-height: 3.194066749vh;
                font-size: 1.2vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake11 {
      padding: 0.843016vh 9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight{
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.1vw;
        font-family: 'Times New Roman';
        font-weight: bold;
        //color: #2168DB;
        line-height: 4vh;
      }

      .heighLight1{
        text-align: left;
        margin: 0 auto 3vh;
        width: 81vw;
        height:fit-content;
        font-size: 1.2vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 3.5vh;
      }

      ::v-deep .el-tabs__nav {
        text-align: center;
        float: none;
      }

      ::v-deep .el-tabs__item {
        width: 50%;
        font-size: 1.2vw !important;
        padding: 2vh 5vh;
        height: fit-content;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #316fff;
      }

      ::v-deep .el-tabs__header {

        margin: 0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #316fff;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 85vh;;
            overflow: hidden;

            .iframeStyle {
              background-color: #ececec;
              margin: -10vh 0 0 -9.8vw;
              width: 100vw;
              height: 105vh;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }

            .iframeStyle iframe {
              background-color: #ececec;
              width: 100vw;
              min-height: 107vh;
              height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }

            .imgDesign {

              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 2vh 3vw;
              width: 100%;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;

              .Title1 {

                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: bold;
                line-height: 1.2vw;
              }

              .Title2 {
                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: 'Times New Roman';
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }

  }

}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 450px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel21.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif;
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color: #fff;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }
    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: white;
      line-height: 14px;
    }
    .layer2 {
      position: absolute;
      left:0;
      .title1 {
        padding:0 15px;
        margin:25px auto;
        width: fit-content;
        text-align: center;
        height: fit-content ;
        font-size: 23px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height:26PX;
      }

      .title2 {
        margin: 25px auto 0 auto;
        width: 90%;
        text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 22px;
      }
      .title3 {
        margin: 18px auto;
        width: 80%;
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {

      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }


          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }

          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }
  .screenmake1{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            //position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 150px;
            opacity: 1;
            background-image: url("../../assets/plug11.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 220px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              height: 150px;
              margin-top: -5px;

              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 10px 40px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake21{
    width: 100vw;
    height: fit-content;
    margin: 20px 0px;
    padding:0px  4.6875vw 0px 4.6875vw;
    background: white;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 10px;
      grid-template-columns: minmax(0,1fr) ;
      column-gap: 5px;
      .card{
        cursor: pointer;
        width: 100%;
        height:fit-content ;
        display: flex;
        padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh ;
        opacity: 1;
        .title1{
          margin: 5px auto;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
        }
        .title2{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .title3{
          margin-top: 5px;

          line-height: 22px;
          font-size: 14px;
        }
        .titleDesign{
          width: 90%;
          margin: 0 0 0 15px;
        }
        .back{
          //background: white;
          border-radius: 9px;
          column-gap: 5px;
          position: relative;
          padding: 0 10px 0 10px;
          height:fit-content;
          display: flex;
          overflow:hidden;
          .imgDesign{
            width: 45px;
            height:45px;
            margin:0 auto 15px auto;
            opacity: 1;
          }


        }

      }

      ::v-deep .el-carousel__container{
        height: 65.87268232385661vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }
      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }
      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake11{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            //position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 150px;
            opacity: 1;
            background-image: url("../../assets/assisted1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 30px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 30px;
              margin-top: -5px;
              height: 30px;
              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 10px 40px;
              text-align: left;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/alarm1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 163px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 100%;
              margin-top: -146px;
              height: 150px;
              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin:13px 15px 10px 13px;
              text-align: left;
              height: fit-content;
              font-size:14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;


    padding: 20px 15px;
    background: black;

    .heighLight {
      text-align: center;
      height: 21px;

      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;

        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
