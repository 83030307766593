<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">   <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <kefu @toptop="topTop"></kefu>
<!--          <div class="background">-->
<!--             -->
<!--            &lt;!&ndash;     文字&ndash;&gt;-->
<!--            <div v-zoom-in="{duration:800}" class="titlle1">Enterprise AI Transformation Consulting</div>-->
<!--            <div v-zoom-in="{duration:800}" class="title4">Help customer companies plan and formulate overall artificial intelligence transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers' artificial intelligence transformation.-->
<!--            </div>-->
<!--            <div v-zoom-in="{duration:800}" class="titlle3" @click="clickConsult('Oil & Gas Generic AI')">-->
<!--              Product Inquiry-->
<!--            </div>-->
<!--          </div>-->
          <servicescomponents :imagepath="'1'" :title1="'Enterprise AI Transformation Consulting'" :title2="'*The solution is the field of Oil & Gas development technology services and AI model fusion, through the field of the solution to provide services to clients, for further understanding of the solution cooperation, please feel free to contact us.'" @dialogVisible="dialogVisible=true"></servicescomponents>
          <div class="screenmake21" style="padding:7.7873918418vh 11.0976314269vw 0">
            <div v-sliden-in="{duration:800}" class="heighLight" style="font-size: 1.7vw;font-weight: unset">  Business Pain Points and Challenges</div>
            <div class="line"></div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/professional.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Professional Talents
                    </div>
                    <div class="title2">
                      the business complexity cannot be fully grasped. The formulated transformation plan does not match the business needs of the enterprise, and the work lacks a system.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Planning1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Difficult to Accurately Assess The Scheme Planning
                    </div>
                    <div class="title2">
                      It is difficult to accurately assess the scheme planning and technical risks, and the project implementation and effect evaluation face great challenges.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Service Offerings</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/consult1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li style="margin-bottom: 1vh">Enterprise AI transformation plan formulation</li>
                      <li style="margin-bottom: 1vh">Enterprise AI project implementation plan design</li>
                      <li style="margin-bottom: 1vh">AI Expert Consulting Services</li>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="screenmake1">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div  class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                 <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
<!--          <div class="background">-->
<!--            &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->
<!--            <headtip @showDialog="showPartner"></headtip>-->
<!--            &lt;!&ndash;     文字&ndash;&gt;-->
<!--            <div class="layer2">-->
<!--              <div v-zoom-in="{duration:800}" class="titlle1">Enterprise AI Transformation Consulting</div>-->
<!--              <div v-zoom-in="{duration:800}" class="title4">Help customer companies plan and formulate overall artificial intelligence transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers' artificial intelligence transformation.</div>-->
<!--            </div>-->
<!--            <div class="title3" @click="clickConsult('Oil & Gas Generic AI')">-->
<!--              Product Inquiry-->
<!--            </div>-->
<!--          </div>-->
          <service-header-mobile :imagepath="'/peixunBanner.png'" :back-height="480" :title1="'Enterprise AI Transformation Consulting'"
                                 :title2="'Help customer companies plan and formulate overall artificial intelligence transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers\' artificial intelligence transformation.'"
                                 @clickbtn1="showPartner" @clickbtn2="dialogVisible=true" ></service-header-mobile>
          <div class="screenmake21" >
            <div v-sliden-in="{duration:800}" class="heighLight" >  Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/professional.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Lack of Professional Talents
                    </div>
                    <div class="title2">
                      the business complexity cannot be fully grasped. The formulated transformation plan does not match the business needs of the enterprise, and the work lacks a system.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/Planning1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Difficult to Accurately Assess The Scheme Planning
                    </div>
                    <div class="title2">
                      It is difficult to accurately assess the scheme planning and technical risks, and the project implementation and effect evaluation face great challenges.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Service Offerings</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                     <img loading="lazy" style="border-radius: 9px" src="@/assets/consult1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li>Enterprise AI transformation plan formulation</li>
                      <li>Enterprise AI project implementation plan design</li>
                      <li>AI Expert Consulting Services</li>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>

          <div class="screenmake1">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div v-sliden-in="{duration:800}" class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                 <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import Servicescomponents from "@/views/services/components/servicescomponents.vue";
import ServiceHeaderMobile from "@/views/services/components/serviceHeaderMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
  },
  name: "",

  props: [],

  components: {ServiceHeaderMobile, Servicescomponents, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

       return {
          changeVisable:1,title:"",
      pageTitle: 'Enterprise AI Transformation Consulting_Anxun Oil & Gas GPT',
      pageDescription: 'Help customer companies plan and formulate overall artificial intelligence transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers artificial intelligence transformation.',
     pageKeywords: 'Training, artificial intelligence training, artificial intelligence transformation training, artificial intelligence transformation consulting, Anxun Oil & Gas Pass, Anxun Oil & Gas Pass Intelligent Technology Co., Ltd., Oil & Gas, Oil & Gas GPT, Oil & Gas GPT Cloud Platform',
      model:false, scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/traning3.png"),
        title1:"Enterprise AI Transformation Consulting",
        title2:"Empower oil & gas eco-enterprises to transform through artificial intelligence, leverage the advantages of Anton's oil & gas technology industry, and provide oil & gas customers with services such as artificial intelligence transformation planning formulation, artificial intelligence project design, and expert consultation.",
      },
      ]
    }

  },

  methods: {
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      window.scrollTo({
         top: 0,
         behavior: 'smooth'
       });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },

    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Enterprise AI Transformation Consulting_Anxun Oil & Gas GPT',
        pageDescription: 'Help customer companies plan and formulate overall artificial intelligence transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers artificial intelligence transformation.',
       pageKeywords: 'Training, artificial intelligence training, artificial intelligence transformation training, artificial intelligence transformation consulting, Anxun Oil & Gas Pass, Anxun Oil & Gas Pass Intelligent Technology Co., Ltd., Oil & Gas, Oil & Gas GPT, Oil & Gas GPT Cloud Platform',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    //width: 100%;
    //height: 100vh;
    .background{
      background-color: #a9b6e6;
    }
    //.background{
    //  padding: 7.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    //  width: 100%;
    //  height: 72.41285537700865vh;
    //  background-image: url("../../assets/peixunBanner.png");
    //  background-position: center;
    //  background-size:cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //  .titlle1 {
    //    margin-top: 15.09270704573548vh;
    //    text-align: center;
    //    font-size: 3.0151935298vw;
    //     font-family: 'Times New Roman';
    //    font-weight: 400;
    //    color: #111111;
    //    line-height: 2.84301606922126vh
    //
    //  }
    //  .title4 {
    //    text-align: center;
    //    margin: 6.82076637824475vh auto 0 auto;
    //    width: 58.45869439630272vw;
    //    font-size: 1.0287117273vw;
    //    font-family: 'Times New Roman';
    //    font-weight: 400;
    //    color:#111111;
    //    line-height: 4.31520395550062vh;
    //  }
    //  .titlle3 {
    //    margin: 8.07911001236094vh auto;
    //    cursor: pointer;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    font-size: 1vw;
    //    font-family: 'Times New Roman';
    //    font-weight: 400;
    //    color: #ffffff;
    //    line-height: 3.3374536465vh;
    //    text-align: center;
    //    border-radius: 9.1px;
    //    width: fit-content;
    //    padding: 2.4624227441vh 1.1989601386vw;
    //    height: 4.5624227441vh;
    //    background: #2f6eff;
    //    border: #2f6eff 1px solid;
    //
    //  }
    //  .titlle3:hover{
    //
    //    color: #2168DB;
    //    border: #2168DB 1px solid;
    //    background: rgba(255, 255, 255, 0.5);
    //  }
    //}
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/peixunBanner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 18.1495673671199vh;
        left: 9.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 6.82076637824475vh;
          width: 33.45869439630272vw;
          height: fit-content;
          font-size: 1.0597920277vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 4.31520395550062vh;
        }
        .title4{
          height: 8.77626699629172vh;
          font-size: 1.6060658579vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }
        .titlle3{
          margin-top: 2.07911001236094vh;
          //cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 9vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;
        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 5.7873918418vh 11.0976314269vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:45vw;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 3.5248454883vh 2.5248454883vw 2.5248454883vh 3vw;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              color: black;
              width: 40%;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                //text-align: justify;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }
              .title3{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size:  1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;
      //background-color: #ebf1fd;
      .heighLight{
        color:white;
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        .card{
          ////cursor: pointer;
          margin:0 auto;
          height:65vh;
          width: 86%;
          padding:0.98887515451174vh 0 0.98887515451174vh 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:64vh;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;

              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              color: white;
              width: 30vw;
              margin:auto;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top:10px;
                line-height: 4vh;
                font-size: 0.9243212016vw;
              }
              .success{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                column-gap: 1vw;
                margin-top: 2vh;
                .suc1{
                  width: 100%;
                  height: 8vh;
                  .title3{
                    text-align: center;
                    margin-top: 2vh;
                    font-size: 0.8243212016vw;
                  }

                }

              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:68vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 550px;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          // //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .background{
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 400px;
      background-image: url("../../assets/peixunBanner.png");
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
      position: relative;
      /* Add any additional styling as needed */
      .layer2 {
        position: absolute;
        left: 0;

        .titlle1 {
          margin: 25px auto 0 auto;
          width: fit-content;
          text-align: center;
          height: fit-content;
          font-size: 22px;
          font-family: 'Times New Roman';
          font-weight: 500;
          color: #111111;
          line-height: 22px;

        }

        .title4 {
          margin: 25px auto;
          width: 90%;
          text-align: center;
          height: 17px;
          font-size: 12px;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
      }
      .title3 {
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
         font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        line-height: 15px;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;


      }

    }
    .screenmake21{
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            background: white;
            border-radius: 9px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake1{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 5px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:10px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:460px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -135px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
